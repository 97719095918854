import React from "react";
import { Link } from "react-router-dom";


export default function Plan60(props){

    return(
      !props.soloLectura ?
        <div className="flex flex-row w-full justify-center space-x-[69px] pt-[5%] pb-[108px]">
           <Link to={props.regalo ? '/crear_su_perfil' : '/crear_mi_perfil'}>
              <button onClick={()=>{props.saveData(props.plan60)}}>
                <div 
                className="border-solid border-[#88411E] bg-white border-4 flex 
                hover:border-6 flex-col text-center w-[396px]">
                  <div className="text-planes h-[157px] pt-[20px]">
                    {props.plan60.cantidadPreguntas} preguntas
                    <div className="mt-[24.5px] mb-[27.5px] border-b-2 border-[#88411E] ml-[155px] w-[88px]"/>
                  </div>
                  <div className="text-[#717171] font-work-sans text-[19px]">
                   {/* MÁXIMO {props.plan60.paginas} PÁGINAS. */}
                    {/* <br/><br/> */}
                    10 FOTOS
                    <br/><br/>
                    DURANTE 3 MESES RECIBIRÁS 5 <br /> PREGUNTAS ACERCA DE TU VIDA <br/>
                    A LA SEMANA
                    <br/><br/>
                    TE ENVIAREMOS 1 LIBRO.
                    <br/><br/>
                   
                  </div>
                  <div className="bg-[#88411E] text-white h-[54px] text-xl font-work-sans text-[20px] pt-3">
                    PAGAR ${props.plan60.precioPlan}
                  </div>
                </div>
              </button>
            </Link>
            </div>
        :
        <div className="flex flex-row w-full justify-center space-x-[69px] pt-[5%] pb-[108px]">
        
             <div 
             className="border-solid border-[#88411E] bg-white border-4 flex 
             hover:border-6 flex-col text-center w-[396px]">
               <div className="text-planes h-[157px] pt-[20px]">
                 {props.plan60.cantidadPreguntas} preguntas
                 <div className="mt-[24.5px] mb-[27.5px] border-b-2 border-[#88411E] ml-[155px] w-[88px]"/>
               </div>
               <div className="text-[#717171] font-work-sans text-[19px]">
                {/* MÁXIMO {props.plan60.paginas} PÁGINAS. */}
                 {/* <br/><br/> */}
                 10 FOTOS
                 <br/><br/>
                 DURANTE 3 MESES RECIBIRÁS 5 <br /> PREGUNTAS ACERCA DE TU VIDA <br/>
                 A LA SEMANA
                 <br/><br/>
                 TE ENVIAREMOS 1 LIBRO.
                 <br/><br/>
                
               </div>
               <div className="bg-[#88411E] text-white h-[54px] text-xl font-work-sans text-[20px] pt-3">
                 PAGAR ${props.plan60.precioPlan}
               </div>
             </div>
         </div>

    )
}