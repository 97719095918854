import { faImages} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Drawer from '../Components/Navbar';
import Swal from "sweetalert2";
import Select from "react-select";
import { CustomStyle, CustomTheme, sexosNP, edades, estadosNP, temasNP, opcionCorta, temas } from "../utils/OpcionesSelect";
import NavBarAdmin from "../Components/NavbarAdmin";

function EditarPregunta(props){
    const pregunta = props.location.state ? props.location.state.pregunta : null
    const [charN, setCharN] = useState(pregunta.pregunta.length)
    const [texto, setTexto] = useState(pregunta.pregunta)
    const [sexo, setSexo] = useState(pregunta.sexo)
    const [tema, setTema] = useState(pregunta.temas)
    const [edad, setEdad] = useState(pregunta.edad)
    const [hijos, setHijos] = useState(pregunta.hijos)
    const [nietos, setNietos] = useState(pregunta.nietos)
    const [estudios, setEstudios] = useState(pregunta.estudios_universitarios)
    const [parentesco, setParentesco] = useState(pregunta.parentesco)
    const [estadoCivil, setEstadoCivil] = useState(pregunta.estado_civil)


    function countChar(val) {
        var len = val.length;
        setCharN(len);
    }
    const Toast = Swal.mixin({
        width: '30%',
        heightAuto: false,
        customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-navbar',
            closeButton: 'swal2-closebtn'
        }
    })
    const Alert1 = () => {Toast.fire({
        toast: true,
        icon: 'success',
        title: 'Pregunta editada exitosamente',
        showConfirmButton: false,
        position: 'top-right',
        timer: 1150,
    })
    }

    const guardar_pregunta = (borrador) => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/preguntas/"+pregunta._id
        var data;
        if(borrador)
        {
            data = {
                pregunta : texto,
                sexo: sexo,
                tema: tema,
                // edad: edad,
                estado_civil: estadoCivil,
                hijos: hijos,
                nietos: nietos,
                estado: false
            }
        }
        else
        {
            data = {
                pregunta : texto,
                sexo: sexo,
                tema: tema,
                // edad: edad,
                estado_civil: estadoCivil,
                hijos: hijos,
                nietos: nietos,
                estado: true
            }
        }
        

        console.log("data", data)
        fetch(url,{
            method:"PUT",
            headers:{
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res =>{
            console.log("primer res",res)
            return res.json()
        }).then(res => {
            Alert1()
            console.log("res22",res)
        }).then(res=>{
                setTimeout(() => {
                    window.location.href = "/preguntas_admin"
                    }, 1000)
                console.log("xd")
            })

    }

    console.log("pegunta", pregunta)
    return(
    <Fragment>
        <div className="font-serif overflow-y bg-[#F9F8F4] h-full">
            {/* <Drawer id={4}/> */}
            <NavBarAdmin id={'preguntas'} />
            <div className="pt-20 pb-10 pl-24 text-[#504E4E]">
                    <h1 className="text-3xl text-titulo-m">Editar pregunta</h1>
                    <br/>
            </div>
            <div className="pb-0">
                <div className="pt-0 pl-24 pr-24 pb-0">
                    <textarea className="bg-[#FFFFFF64] w-full h-44 border-solid border-2 border-[#D1CAB8] p-4 font-sans text-[#504E4E]"
                    value={texto}
                    maxlength="150"
                    onKeyUp={(e)=>countChar(e.target.value)}
                    onChange={(e)=>setTexto(e.target.value)}
                    >
                        
                    </textarea>
                </div>
                <h1 className="font-sans text-xs pl-24 pt-2 text-[#717171]">{charN}/150 CARACTERES</h1>
            </div>


            <div className="grid grid-cols-3 gap-5 text-[#504E4E] pt-10 px-24">
                <div className="flex flex-col">
                    <label className="text-label ">Tema</label>
                        <Select placeholder="Selecciona" options={temasNP} theme={CustomTheme} defaultValue={tema}
                    onChange={setTema} className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>

                    <label className="text-label mt-10">Sexo</label>
                    <Select placeholder="Selecciona" options={sexosNP} theme={CustomTheme} defaultValue={sexo}
                        onChange={setSexo} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"} />
                    {/* <label className="text-label">Sexo</label>
                    <select className="select-memo"
                    value={sexo}
                    onChange={(e)=>setSexo(e.target.value)}
                    >
                        <option value="Masculino">Masculino</option>
                        <option value="Femenino">Femenino</option>
                    </select>

                    <label className="text-label mt-10">Tema</label>
                    <select className="select-memo"
                    value={tema}
                    onChange={(e)=>setTema(e.target.value)}>
                        <option value="Cultura">Cultura</option>
                        <option value="Deportes">Deportes</option>
                        <option value="Historia">Historia</option>
                    </select> */}
                </div>

                <div className="flex flex-col">
                    <label className="text-label">Estado civil</label>
                    <Select placeholder="Selecciona" options={estadosNP} theme={CustomTheme} defaultValue={estadoCivil}
                    onChange={setEstadoCivil} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>
                    <label className="text-label mt-10">Estudios universitarios</label>
                    <Select placeholder="Selecciona" options={opcionCorta} theme={CustomTheme} defaultValue={estudios}
                    onChange={setEstudios} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>
                    {/* <label className="text-label">Estado civil</label>
                    <select className="select-memo"
                    onChange={(e)=>setEstadoCivil(e.target.value)}>
                        <option value={"Soltero/a"}>Soltero/a</option>
                        <option value={"Casado/a"}>Casado/a</option>
                        <option value={"Viudo/a"}>Viudo/a</option>
                        <option value={"Divorciado/a"}>Divorciado/a</option>
                    </select> */}

                    {/* <label className="text-label mt-10">Edad</label>
                    <select className="select-memo"
                    onChange={(e)=>setEdad(e.target.value)}>
                        <option value={0}>1-20</option>
                        <option value={1}>21 - 40</option>
                        <option value={2}>41 - 60</option>
                        <option value={3}>61 - 80</option>
                        <option value={4}>Más de 80</option>         
                    </select> */}
                </div>

                <div className="flex flex-col">
                    <label className="text-label">Hijos</label>
                    <Select placeholder="Selecciona" options={opcionCorta} theme={CustomTheme} defaultValue={hijos}
                    onChange={setHijos} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>
                    <label className="text-label mt-10">Nietos</label>
                    <Select placeholder="Selecciona" options={opcionCorta} theme={CustomTheme} defaultValue={nietos}
                    onChange={setNietos} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>
                    {/* <select className="select-memo">
                    <option>5</option>
                    </select> */}
                </div>
            </div>

            <div className="flex justify-center">
                <div className="pt-8 pl-60">
                    {/* <Link to={'/planes'} className="pl-28">
                        <button 
                        className="w-60 px-12 mr-6 py-2 text-botones estilo-botones">
                        ELIMINAR
                        </button>
                    </Link> */}
                    <button 
                    onClick={()=>guardar_pregunta(true)}
                    className="w-96 mr-6 px-12 py-2 text-botones estilo-botones">
                    GUARDAR BORRADOR
                    </button>
                    <button 
                    onClick={()=>guardar_pregunta(false)}
                    className="w-96 px-12 py-2 text-botones estilo-botones">
                    GUARDAR PREGUNTA
                    </button>
                </div>
            </div>
        </div>
    </Fragment>
    );
}

export default EditarPregunta;