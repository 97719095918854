/* eslint-disable jsx-a11y/alt-text */
import React,{useState} from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/imgs/logo negro.png"
import FloatingLabelInput from 'react-floating-label-input';
// import SweetAlert from 'react-bootstrap-sweetalert';
import Auth from '../middleware/Auth';
import Swal from 'sweetalert2';
// import { Navigate } from 'react-router-dom';

function Login()
{
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [Alert, setAlert] = useState(null);
  
  const iniciarSesion = async() => 
  {
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/login"

    var data = {
      correo: correo,
      password: password
    }
    console.log(data)
    fetch(url,{
      method: "POST",
      headers: {"Content-type": "application/json"},
      body: JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(response=>{
      console.log(response)
      if(response.user!=null)
      {
        if(response.user.emailVerified === true)
        {
          setAlert(
            Swal.fire({
              title: 'Bienvenido',
              icon: 'success',
              customClass:{
                popup: 'swal2-border',
                confirmButton: 'swal2-confirm',
                title: 'text-label'
              },
              confirmButtonText: 'ENTRAR',
              confirmButtonColor: '#A5DC86',
            })
            .onConfirm(
              localStorage.setItem("memoria_creativa_user", JSON.stringify(response.user)),
              Auth.authenticateToken(response.token),
              setTimeout(() => {
                window.location.href = "/mi_libro"
              }, 1000)
            )
          )
        }
        else {
          setAlert(
            Swal.fire({
              title: 'Falta activar cuenta',
              icon: 'error',
              customClass:{
                popup: 'swal2-border',
                confirmButton: 'swal2-confirm',
                title: 'text-label'
              },
              confirmButtonText: 'CERRAR',
            })
            .onConfirm(setTimeout(() => {
          
              window.location.reload()},1000))
          )
        }
      }
      else
      {

          Swal.fire({
            title: 'Credenciales inválidas',
            icon: 'error',
            width: '40%',
            customClass:{
              popup: 'swal2-border',
              confirmButton: 'swal2-confirm',
              title: 'text-label',
              closeButton: 'swal2-closebtn'
            },
            confirmButtonText: 'CERRAR'
          }).then((result)=>{
            if (result.isConfirmed){
              Swal.close()
            }
          })
        
        
      }
    }).catch((err)=>{
        console.log("aqui")
    })
  }

  return(
    <Fragment>
      {Alert}
      <div 
      className="flex flex-col font-serif place-items-center bg-page h-full w-full">
        <div 
        className="pt-32">
          <img className="h-40 w-40" src={Logo}/>
        </div>
        <div 
        className="text-center text-titulo">
          Escribe tu historia
        </div>
        
        <div className="mt-10 grid grid-cols-2 gap-x-10">
          <div className="columna1">
            <div className="font-cormorant w-[338px] font-semibold text-[26px] text-letra1">Usuario (Correo electrónico)</div>
            <input type={'text'} value={correo} onChange={e=>setCorreo(e.target.value)}
            className="text-inputs w-full bg-page select-memo "></input>
          </div>
          <div className="columna2">
          <div className="font-cormorant font-semibold w-[338px] text-[26px] text-letra1">Contraseña</div>
            <input type={'password'} value={password} onChange={e=>setPassword(e.target.value)}
            className="text-inputs w-full bg-page select-memo "></input>
          </div>
        </div>
        <div className="mt-20">
            <button 
            className="mr-10 w-[314px] h-[67px] estilo-botones text-botones"
            onClick={()=>iniciarSesion()}>
              ACCEDER
            </button>
            {/* <Link to={'/inicio'}>
            <button 
            className="w-[314px] h-[67px] estilo-botones text-botones">
              CREAR USUARIO
            </button>
          </Link> */}
          <Link to={'/inicio'}>
            <button 
            className="w-[314px] h-[67px] estilo-botones text-botones">
              VOLVER
            </button>
          </Link>
        </div>

        <div className="mt-8"><Link className="text-botones2 tracking-wide w-44" 
        to={'/cambiar_clave'}>OLVIDÉ MIS ACCESOS</Link></div>
      </div>
    </Fragment>
  );
}

export default Login;