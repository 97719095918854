import React, { useState } from "react";
import { useEffect } from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Drawer from '../Components/Navbar';
import moment from 'moment';
import iconoCambiar from '../assets/iconos/cambiar.png'
import iconoVer from '../assets/iconos/ver.png'
import iconoEditar from '../assets/iconos/editar.png'
import Swal from "sweetalert2";

const user = JSON.parse(localStorage.getItem("memoria_creativa_user"))

function MisPreguntas()
{
  const [historia, setHistoria] = useState(null);
  const[preguntas, setPreguntas] = useState([]);

  const cargar_preguntas = async() => {
    var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/obtenerHistoriaCorreo"

    var data = {
        correo: user.correo
    }

    await fetch(url,{
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        body: JSON.stringify(data)
    }).then((res)=>{
        return res.json();
    }).then(async (res)=>{
        console.log("res.json ",res.historia)
        let preg = [];
        for(let pregunta of res.historia.preguntas)
        {
          if(pregunta.fecha_envio != null)
          {
            preg.push(pregunta)
          }
        }

        preg.sort(function (a, b) {
          if (a.numero > b.numero) {
            return 1;
          }
          if (a.numero < b.numero) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setHistoria(res.historia);
        setPreguntas(preg);
    })
  }

  const confirmarCambio = async(pregunta) => {
    Swal.fire({
      title: '¿Está Seguro?',
      icon: 'info',
      customClass:{
        popup: 'swal2-border',
        confirmButton: 'swal2-confirm',
        title: 'text-label',
        closeButton: 'swal2-closebtn',
      },
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      showCancelButton: true
    }).then((res)=>{
      if(res.isConfirmed){
          Swal.close()
          cambiarPregunta(pregunta)
      }})
  }

  const cambiarPregunta = async(pregunta) => {

    var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/"+historia._id+"/cambiarPregunta"

    var data = {
      pregunta: pregunta,
      usuario: user
    }

    await fetch(url,{
      method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data)
    }).then((res)=>{
      return res.json();
    }).then((res)=>{
      if(res.status == 200)
      {
        Swal.fire({
          title: 'Pregunta Cambiada',
          icon: 'success',
          customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-label',
            closeButton: 'swal2-closebtn',
          },
          confirmButtonText: 'Aceptar',
          // cancelButtonText: 'Cancelar',
          // showCancelButton: true
        }).then((res)=>{
          if(res.isConfirmed){
              Swal.close()
          }})

        let preg = [];
        for(let pregunta of res.historia.preguntas)
        {
          if(pregunta.fecha_envio != null)
          {
            preg.push(pregunta)
          }
        }

        preg.sort(function (a, b) {
          if (a.numero > b.numero) {
            return 1;
          }
          if (a.numero < b.numero) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        setHistoria(res.historia);
        setPreguntas(preg);
      }
      else
      {
        Swal.fire({
          title: 'No se pudo cambiar la pregunta',
          icon: 'error',
          customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-label',
            closeButton: 'swal2-closebtn',
          },
          confirmButtonText: 'Aceptar',
          // cancelButtonText: 'Cancelar',
          // showCancelButton: true
        }).then((res)=>{
          if(res.isConfirmed){
              Swal.close()
          }})
      }
    })
  }

  useEffect(()=>{
    cargar_preguntas()
  },[])

  return(
    <Fragment>
    <div className="overflow-x-hidden overflow-y-hidden bg-page h-full">
      <Drawer id={4}/>
      <div className="flex justify-center">
        <div className="flex flex-row static w-11/12 mt-10 justify-start h-40">
          <div className="text-titulo">
            Mis preguntas
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="w-11/12 max-h-128 overflow-y-auto overflow-x-hidden">
          <div className="table w-full">
            { /*  Cabeceras de la tabla      */}
            <div className="table-header-group">
                <div className="table-row text-cabecera-tabla">
                    <div className="table-cell borde-tabla w-[8%]">N°</div>
                    <div className="table-cell borde-tabla w-[12%]">Fecha</div>
                    <div className="table-cell borde-tabla w-[12%]">Estado</div>
                    <div className="table-cell borde-tabla w-[56%]">Pregunta</div>          
                    <div className="table-cell borde-tabla w-[12%]">Acciones</div>          
                </div>
            </div>
            { /*         FILAS        */     }
            {
              preguntas.map((pregunta)=>{
                console.log("PREGUNTA ",pregunta)
                if(pregunta.Estado != 2)
                {
                  return(
                    <div className="table-row-group">
                      <div className="table-row text-tabla">
                        <div className="table-cell borde-tabla py-2 align-middle">{pregunta.numero}</div>
                        <div className="table-cell borde-tabla py-2 align-middle">{moment(pregunta.fecha_envio).format('DD-MM-YYYY')}</div>
                        <div className="table-cell borde-tabla py-2 align-middle">{pregunta.Estado == 1 ? "Respondida" : "Pendiente"}</div>
                        <div className="table-cell borde-tabla py-2 align-middle">{pregunta.pregunta}</div>
                        <div className="table-cell borde-tabla py-2 align-middle">
                          {
                            pregunta.Estado == 0 && historia.vencido == false?
                            <>
                              <Link to={{pathname:"/mi_respuesta", state: {pregunta : pregunta, historia: historia}}}>
                                <button className="mr-3" title="Contestar Pregunta">
                                    <img className="w-[60px] h-[60px]" src={iconoEditar} alt="responder" />
                                </button>
                              </Link>
                                <button title="Cambiar Pregunta" className="mr-3" onClick={()=>{confirmarCambio(pregunta)}}>
                                  <img className="w-[60px] h-[60px]" src={iconoCambiar} />
                                </button>
                            </>
                            :
                            <Link to={{pathname:"/mi_respuesta", state: {pregunta : pregunta, historia: historia}}}>
                              <button title="Ver Respuesta" className="mr-3">
                                <img className="w-[60px] h-[60px]" src={iconoVer} />
                              </button>
                            </Link>
                          }
                          
                        </div>
                      </div>
                    </div>
                  );
                }
              })
            }
          </div>
        </div>
      </div>
    </div>
    </Fragment>
  );
}

export default MisPreguntas;