import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { temasNP, CustomStyle, CustomTheme, numero_preguntas } from "../utils/OpcionesSelect";
import Select from "react-select";
import Swal from "sweetalert2";




function TemasDeInteres(props){
  console.log("temas props",props.location.state)
  const [tema, setTema] = useState(null)
  const [regalo, setRegalo] = useState("")



  const [sumaPreguntas, setSumaPreguntas] = useState(0);




  console.log('personalizarLibroData',JSON.parse(sessionStorage.getItem('personalizarLibroData')));
  console.log(JSON.parse(sessionStorage.getItem('userData')));
  console.log(JSON.parse(sessionStorage.getItem('planData')));
  console.log(JSON.parse(sessionStorage.getItem('emailData')));

  const plan = JSON.parse(sessionStorage.getItem('planData')).plan;

  const [primerTema,setPrimerTema] = useState(null);
  const [segundoTema,setSegundoTema] = useState(null);
  const [tercerTema,setTercerTema] = useState(null);
  const [quintoTema, setQuintoTema] = useState(null);
  const [cuartoTema, setCuartoTema] = useState(null);
  const [sextoTema,  setSextoTema] = useState(null);
  const [septimoTema,setSeptimoTema] = useState(null);
  const [octavoTema, setOctavoTema] = useState(null);
  const [novenoTema,setNovenoTema]      = useState(null)
  const [decimoTema,setDecimoTema] = useState(null)
  const [undecimoTema,setUndecimoTema] = useState(null)
  const [duodecimoTema,setDuodecimoTema] = useState(null)
  const [preguntasPrimer,setPreguntasPrimer] = useState(0);
  const [preguntasSegundo,setPreguntasSegundo] = useState(0);
  const [preguntasTercero,setPreguntasTercero] = useState(0);
  const [preguntasCuarto,setPreguntasCuarto] = useState(0);
  const [preguntasQuinto , setPreguntasQuinto] = useState(0);
  const [preguntasSexto , setPreguntasSexto]  = useState(0);
  const [preguntasSeptimo , setPreguntasSeptimo] = useState(0);
  const [preguntasOctavo , setPreguntasOctavo] = useState(0);
  const [preguntasNoveno, setPreguntasNoveno] = useState(0);
  const [preguntasDecimo, setPreguntasDecimo] = useState(0);
  const [preguntasUndecimo, setPreguntasUndecimo] = useState(0);
  const [preguntasDuodecimo, setPreguntasDuodecimo] = useState(0);


  const saveData = () => {

    var sumaPlan = (Number(preguntasPrimer) + 
    Number(preguntasSegundo) + 
    Number(preguntasTercero) + 
    Number(preguntasCuarto) + 
    Number(preguntasQuinto) + 
    Number(preguntasSexto) + 
    Number(preguntasSeptimo) + 
    Number(preguntasOctavo) + 
    Number(preguntasNoveno) + 
    Number(preguntasDecimo) +
    Number(preguntasUndecimo) + 
    Number(preguntasDuodecimo)
    )
    var temasMinimos = (primerTema!=null && segundoTema!=null && tercerTema!=null && cuartoTema!=null && quintoTema!=null && sextoTema!=null)
  console.log("sumita",sumaPlan)
  console.log("primero,segundo,tercero",preguntasPrimer.value,preguntasSegundo.value,preguntasTercero.value)

  console.log("plan",plan)

  if(sumaPlan === 48 && temasMinimos){
    var data={
      primerTema:primerTema,
      segundoTema:segundoTema,
      tercerTema:tercerTema,
      cuartoTema:cuartoTema,
      quintoTema:quintoTema,
      sextoTema:sextoTema,
      septimoTema:septimoTema === null ? 0 : septimoTema,
      octavoTema:octavoTema === null ? 0 : octavoTema,
      novenoTema:novenoTema === null ? 0 : novenoTema,
      decimoTema:decimoTema === null ? 0 : decimoTema,
      undecimoTema:undecimoTema === null ? 0 : undecimoTema,
      duodecimoTema:duodecimoTema === null ? 0 : duodecimoTema,
      preguntasPrimer:preguntasPrimer,
      preguntasSegundo:preguntasSegundo,
      preguntasTercero:preguntasTercero,
      preguntasCuarto:preguntasCuarto,
      preguntasQuinto:preguntasQuinto,
      preguntasSexto:preguntasSexto,
      preguntasSeptimo:preguntasSeptimo,
      preguntasOctavo:preguntasOctavo,
      preguntasNoveno:preguntasNoveno,
      preguntasDecimo:preguntasDecimo,
      preguntasUndecimo:preguntasUndecimo,
      preguntasDuodecimo:preguntasDuodecimo,

    }
    sessionStorage.setItem('temasInteresData', JSON.stringify(data));
    console.log("datita rika hmmm",data)
    document.getElementById('continue').click()
  // }else{
  //   Swal.fire({
  //     title: 'Debe escoger 6 temas mínimo',
  //     icon: 'error',
  //     width: '35%',
  //     showCloseButton: true,
  //     customClass:{
  //       popup: 'swal2-border',
  //       confirmButton: 'swal2-confirm',
  //       title: 'swal2-title',
  //       closeButton: 'swal2-closebtn',
  //     },
  //     confirmButtonText: 'VOLVER'

  //   })
  // }
  }if(isNaN(sumaPlan)){
    Swal.fire({
      title: 'Debe escoger 6 temas mínimo',
      icon: 'error',
      width: '35%',
      showCloseButton: true,
      customClass:{
        popup: 'swal2-border',
        confirmButton: 'swal2-confirm',
        title: 'swal2-title',
        closeButton: 'swal2-closebtn',
      },
      confirmButtonText: 'VOLVER'

    })
  }
  else if(sumaPlan != 48){
    Swal.fire({
      title: 'Número de preguntas erróneo',
      icon: 'error',
      width: '35%',
      html: '<p class="text-tabla">La suma de preguntas debe ser <g></g> y usted lleva <b><f></f></b></p>',
      showCloseButton: true,
      didOpen: () => {
        const a = Swal.getHtmlContainer().querySelector('f')
        const b = Swal.getHtmlContainer().querySelector('g')
        a.textContent = sumaPlan
        b.textContent = plan.cantidadPreguntas
      },
      customClass:{
        popup: 'swal2-border',
        confirmButton: 'swal2-confirm',
        title: 'swal2-title',
        closeButton: 'swal2-closebtn',
      },
      confirmButtonText: 'VOLVER'

    })
  }
  else if(!temasMinimos){
    Swal.fire({
      title: 'Debe escoger 6 temas como mínimo',
      icon: 'error',
      width: '35%',
      showCloseButton: true,
      customClass:{
        popup: 'swal2-border',
        confirmButton: 'swal2-confirm',
        title: 'swal2-title',
        closeButton: 'swal2-closebtn',
      },
      confirmButtonText: 'VOLVER'

    })
  }
  }
  

  useEffect(()=>{
    const params = new URLSearchParams(props.location.search); 
    const regalo = params.get('regalo');
    console.log("regaloo",regalo)
    setRegalo(regalo)

  },[])



  useEffect(()=>{
    
    setSumaPreguntas(sumaPreguntas+Number(preguntasDuodecimo))



  },[preguntasDuodecimo])


  return(
    <Fragment>
      <div 
      className="bg-page overflow-x-hidden h-full w-full flex flex-col text-center place-items-center">
        <div className="pb-4 pt-12 text-titulo ">
          Temas de Interés
        </div>
        <div className="text-parrafo1">
          Selecciona los temas de interés y < br /> la cantidad de preguntas para cada uno
        </div>

        <div className="flex flex-col pb-4 pt-10 font-cormorant text-letra1 font-semibold text-[45px]">
          Plan de {plan.cantidadPreguntas} preguntas
          <div className="mt-3 border-b-2 border-[#528AAF] ml-32 w-1/4 mb-4"></div>
        </div>

{/*              ============  PREGUNTAS ========= */}

        <div className="bg-page grid grid-cols-4 w-11/12 gap-x-15 gap-y-10">
            <div>
                <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                        Primer Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setPrimerTema} className="select-memo" styles={CustomStyle}/>

                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>  
                  <select className="select-memo" onChange={(e)=>setPreguntasPrimer(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
                                             
                </div>
            </div>
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                     Segundo Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setSegundoTema} className="select-memo" styles={CustomStyle}/>


                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>     
                   <select className="select-memo" onChange={(e)=>setPreguntasSegundo(e.target.value)}>
                   
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
        
                </div>
            </div>
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                     Tercer Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setTercerTema} className="select-memo" styles={CustomStyle}/>


                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>     
                   <select className="select-memo" onChange={(e)=>setPreguntasTercero(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
              </div>
            </div>
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
              Cuarto Tema
            </div>
          <div className="flex flex-col text-left text-[#504E4E] text-lg mr-4">
            <label className='mb-2 text-label2'>
                Selecciona el tema para preguntar
            </label>
            <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setCuartoTema} className="select-memo" styles={CustomStyle}/>

            <label className="pt-8 mb-2 text-label2">
              Selecciona la cantidad de preguntas
            </label>     
                          <select className="select-memo" onChange={(e)=>setPreguntasCuarto(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select> 
            </div>
          </div>

          <div>
                <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                        Quinto Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setQuintoTema} className="select-memo" styles={CustomStyle}/>


                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>     
                   <select className="select-memo" onChange={(e)=>setPreguntasQuinto(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
                </div>
            </div>
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                     Sexto Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setSextoTema} className="select-memo" styles={CustomStyle}/>


                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>     
                   <select className="select-memo" onChange={(e)=>setPreguntasSexto(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
                </div>



            </div>

            
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                     Séptimo Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setSeptimoTema} className="select-memo" styles={CustomStyle}/>


                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>     
                   <select className="select-memo" onChange={(e)=>setPreguntasSeptimo(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
              </div>
            </div>
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
              Octavo Tema
            </div>
          
          <div className="flex flex-col text-left text-[#504E4E] text-lg mr-4">
            <label className='mb-2 text-label2'>
                Selecciona el tema para preguntar
            </label>
            <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setOctavoTema} className="select-memo" styles={CustomStyle}/>

            <label className="pt-8 mb-2 text-label2">
              Selecciona la cantidad de preguntas
            </label>     
            <select className="select-memo" onChange={(e)=>setPreguntasOctavo(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
            </div>
          </div>
          <div>
                <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                        Noveno Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setNovenoTema} className="select-memo" styles={CustomStyle}/>


                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>     
                   <select className="select-memo" onChange={(e)=>setPreguntasNoveno(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
                </div>
            </div>
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                     Décimo Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setDecimoTema} className="select-memo" styles={CustomStyle}/>


                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>     
                   <select className="select-memo" onChange={(e)=>setPreguntasDecimo(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
                </div>



            </div>

            
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
                     Undécimo Tema
                </div>
                <div className="flex flex-col text-[#504E4E] text-left text-lg mr-4">
                    <label className='mb-2 text-label2'>
                        Selecciona el tema para preguntar
                    </label>
                    <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setUndecimoTema} className="select-memo" styles={CustomStyle}/>


                   <label className="pt-8 mb-2 text-label2">
                        Selecciona la cantidad de preguntas
                   </label>     
                   <select className="select-memo" onChange={(e)=>setPreguntasUndecimo(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>    
              </div>
            </div>
            <div>
            <div className="font-semibold font-cormorant text-[#717171] text-[40px] text-left pb-5">
              Duodécimo Tema
            </div>
          
          <div className="flex flex-col text-left text-[#504E4E] text-lg mr-4">
            <label className='mb-2 text-label2'>
                Selecciona el tema para preguntar
            </label>
            <Select options={temasNP} theme={CustomTheme} defaultValue={tema}
                 onChange={setDuodecimoTema} className="select-memo" styles={CustomStyle}/>

            <label className="pt-8 mb-2 text-label2">
              Selecciona la cantidad de preguntas
            </label>     
            <select className="select-memo" onChange={(e)=>setPreguntasDuodecimo(e.target.value)}>
                        <option value={1}>1 pregunta</option>
                        <option value={2}>2 preguntas</option>
                        <option value={3}>3 preguntas</option>
                        <option value={4}>4 preguntas</option>
                        <option value={5}>5 preguntas</option>
                        <option value={6}>6 preguntas</option>
                        <option value={7}>7 preguntas</option>
                        <option value={8}>8 preguntas</option>
                        <option value={9}>9 preguntas</option>
                        <option value={10}>10 preguntas</option>
                        <option value={11}>11 preguntas</option>
                        <option value={12}>12 preguntas</option>
                        <option value={13}>13 preguntas</option>
                        <option value={14}>14 preguntas</option>
                        <option value={15}>15 preguntas</option>
                  </select>
            </div>
          </div>
        </div>
        <div className="bg-page flex flex-row space-x-4 justify-center mt-20 mb-40">
          <Link to={regalo === 'true' ? '/crear_su_perfil' : 'crear_mi_perfil'}>
            <button className="estilo-botones py-2 px-24 text-botones">VOLVER</button>
          </Link>
          {regalo === 'true' ? 
          <Link to={{ pathname: "/resumen_compra", state: { regalo: true}}}>
             <button onClick={()=>{saveData()}}
          className="estilo-botones py-2 px-20 text-botones">CONTINUAR</button></Link>
         :
        
         <button onClick={()=>{saveData()}}
      className="estilo-botones py-2 px-20 text-botones">CONTINUAR</button>
         }
        </div>
        <Link id='continue' to={{ pathname: "/resumen_compra", state: { regalo: false}}}></Link>
      </div>
    </Fragment>
  );
};

export default TemasDeInteres;