import React,{useState, useEffect} from 'react'
import {Fragment} from "react";
import { Link } from "react-router-dom";
import libro1 from '../assets/imgs/libro1.png'
import Drawer from '../Components/Navbar';
import moment from 'moment';
import Swal from 'sweetalert2';
// import { format } from 'path';

const user = JSON.parse(localStorage.getItem("memoria_creativa_user"))
console.log("USUARIO ", user)

// TODO :         HACER FUNCION QUE CARGUE PREGUNTAS PENDIENTES/ENVIADAS

function MiLibro(){
    const [historia, setHistoria] = useState(null);
    const [a, setA] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [b, setB] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [pendientes, setPendientes] = useState(0);
    const [total, setTotal] = useState(0);
    const [dias, setDias] = useState(0);
    const [fotos, setFotos] = useState([]);

    const extenderPlazo = async() => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/"+historia._id+"/extenderPlazo"
    
        await fetch(url,{
            method: "GET",
            headers: {
                "Content-type": "application/json",
            },
        }).then((res)=>{
            return res.json();
        }).then(async (res)=>{
            console.log("RES ",res)
            if(res.status == 200)
            {
                Swal.fire({
                    title: 'Plazo Extendido',
                    icon: 'success',
                    width: '35%',
                    // html:'<p class="text-tabla">Revise su correo para solicitar su clave</p>',
                    showCloseButton: true,
                    customClass:{
                        popup: 'swal2-border',
                        confirmButton: 'swal2-confirm',
                        title: 'swal2-title',
                        closeButton: 'swal2-closebtn',
                    },
                    confirmButtonText: 'ENTENDIDO'
                }).then((res)=>{
                    window.location.reload(true);
                })
            }
        })
    }

    const obtenerLibro = async() => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/obtenerHistoriaCorreo"
    
        var data = {
            correo: user.correo
        }
    
        await fetch(url,{
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data)
        }).then((res)=>{
            return res.json();
        }).then(async (res)=>{
            console.log("res.json ",res.historia)
            setHistoria(res.historia)
            ponerFecha(res.historia)
            cargarFotos(res.historia._id)

            let cont = 0;
            let cont2 = 0;

            for(let pregunta of res.historia.preguntas)
            {
                if(pregunta.fecha_envio != null && pregunta.Estado != 1 && pregunta.Estado != 2)
                {
                    console.log("PREG ",pregunta)
                    cont++;
                }
                if(pregunta.respuesta != '' && (pregunta.Estado == 1 && pregunta.Estado != 2) && pregunta.fecha_envio != null)
                {
                    cont2++;
                }
            }
            setPendientes(cont);
            setTotal(cont2);
        })
    }

    const ponerFecha = async(historia) => {
        console.log("HISTORIA ",historia)
        if(historia.extendido)
        {
            setA(moment(historia?.createdAt).add(4,'months').format('YYYY-MM-DD'));
        }
        else
        {
            setA(moment(historia?.createdAt).add(3,'months').format('YYYY-MM-DD'));
        }
    }

    const subirImagen = async(e) => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/imagenes_libros"
        var data = new FormData()

        data.append("historia", historia._id)
        data.append("Imagen", e.target.files[0])
        data.append("Tipo", 'foto')

        await fetch(url,{
            method: "POST",
            body: data
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            console.log("RESPUESTA ",res)
            if(res.status == 200)
            {
                Swal.fire({
                    title: 'Foto agregada',
                    icon: 'success',
                    customClass:{
                      popup: 'swal2-border',
                      confirmButton: 'swal2-confirm',
                      title: 'text-label',
                      closeButton: 'swal2-closebtn',
                    },
                    confirmButtonText: 'Aceptar',
                })
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            else
            {
                Swal.fire({
                    title: 'Foto no agregada',
                    icon: 'error',
                    customClass:{
                      popup: 'swal2-border',
                      confirmButton: 'swal2-confirm',
                      title: 'text-label',
                      closeButton: 'swal2-closebtn',
                    },
                    confirmButtonText: 'Aceptar',
                })
            }
        })
    }

    const cargarFotos = async(id) => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/"+id+"/imagenes"

        await fetch(url,{
            method: "GET",
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            console.log("RESPUESTA GET ",res)
            setFotos(res)
        })
    }

    useEffect(()=>{
        console.log("FECHA ",moment(Date.now()).format('dddd'))
        if(moment(Date.now()).format('dddd') == 'Monday')
        {
            setDias(7);
        }
        if(moment(Date.now()).format('dddd') == 'Tuesday')
        {
            setDias(6);
        }
        if(moment(Date.now()).format('dddd') == 'Wednesday')
        {
            setDias(5);
        }
        if(moment(Date.now()).format('dddd') == 'Thursday')
        {
            setDias(4);
        }
        if(moment(Date.now()).format('dddd') == 'Friday')
        {
            setDias(3);
        }
        if(moment(Date.now()).format('dddd') == 'Saturday')
        {
            setDias(2);
        }
        if(moment(Date.now()).format('dddd') == 'Sunday')
        {
            setDias(1);
        }
        obtenerLibro();
    },[])

    return(
        <Fragment>
            <div className="overflow-y-hidden bg-page w-full h-full">
                <Drawer id={1}/>
            <div className='flex justify-center'>
                <div className='w-11/12'>
                
                <div className="flex pt-8 text-left">
                        <h1 className="text-titulo">Escribe tu historia</h1>
                </div>
                <div className="grid grid-cols-2 gap-2">
                    <div className="pr-20 pb-0 text-label">
                        <h1 className='text-parrafo1'>Aquí encontrarás información acerca del <br/> estado de tu proyecto de memorias.</h1>

                        <img src={libro1} width='55%' className='ml-6'/>
                    </div>
                    <div className="pb-0">
                        <div className="pt-0 pl-24 pr-24 pb-0">
                            <div className="bg-[#FFFFFF64] border-solid border-2 border-[#D1CAB8]">
                                <div className="pl-4 pt-4">
                                    <h1 className="text-navbar">Estado actual de tu libro:</h1>
                                    <br/>
                                    <h1 className="text-label-reg">En {dias} {dias > 1 ? 'días':'día'} te enviaremos una nueva pregunta.</h1>
                                    <br/>
                                    <h1 className="text-label-reg">Faltan {pendientes} preguntas por responder </h1>
                                    <Link to={'/mis_preguntas'} >
                                        <button 
                                        className="w-1/3 text-botones2 tracking-wide estilo-botones mt-1">
                                        IR A MIS PREGUNTAS
                                        </button>
                                        </Link>
                                    <br/>
                                    <br/>
                                    {
                                        console.log("A ",a)
                                    }
                                    <h1 className="text-label-reg">Quedan {historia?.plan.numero_preguntas - total} preguntas por responder en {(Date.parse(a)-Date.parse(b))/(1000 * 60 * 60 * 24) > 0 ? (Date.parse(a)-Date.parse(b))/(1000 * 60 * 60 * 24) : 0} días.</h1>
                                        {
                                            historia?.extendido === false ?
                                                <button className="w-1/3 text-botones2 tracking-wide estilo-botones mt-1" onClick={()=>{extenderPlazo();}}>EXTENDER PLAZO</button>
                                            : 
                                                <button disabled className="w-1/3 text-botones2 tracking-wide estilo-botones mt-1">PLAZO EXTENDIDO</button>
                                        }
                                    <br/>
                                    <br/>
                                    <h1 className="text-label-reg">Quedan {10-fotos.length} fotografías para tus respuestas.</h1>
                                    {
                                        10-fotos.length > 0 && historia?.vencido == false ?
                                        <div className="flex flex-row items-center ">
                                            <label for="foto_libro" className="flex justify-center cursor-pointer w-1/3 text-botones2 tracking-wide estilo-botones mt-1">
                                                SUBIR FOTO
                                            </label>
                                            <input style={{display: "none"}} type="file" onChange={(e)=>{subirImagen(e)}} id="foto_libro"/>
                                        </div>
                                        :
                                        null
                                    }
                                        
                                    <br/>
                                    {/* <br/> */}
                                        <div className="flex flex-row items-center ">
                                            {
                                                fotos.map((foto)=>{
                                                    console.log("FOTO ",foto)
                                                    return(
                                                        <a href={foto.url} target="_blank">
                                                            <img src={foto.url} className="w-14 h-14 m-1"></img>
                                                        </a>
                                                    )
                                                })
                                            }
                                        </div>
                                        {/* <div className="flex flex-row items-center">
                                        {
                                            fotos.map((foto)=>{
                                                return(
                                                    <img className="h-100 w-100" href={foto.url}></img>
                                                )
                                            })
                                        }
                                        </div> */}
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </div></div>
                    </div>
                </div></div>
            </div>
        </Fragment>
    );
}

export default MiLibro;