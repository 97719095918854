import { faImages} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Drawer from '../Components/Navbar';
import NavBarAdmin from "../Components/NavbarAdmin";
// import { Document, Packer, Paragraph, TextRun } from "docx";
// import { render, Document, Text } from 'redocx'

function PreguntasYRespuestas(props){

    const [preguntas, setPreguntas] = useState([]);
    const [libro, setLibro] = useState(null);
    
    useEffect(()=>{
        let array = [];
        setLibro(props.location.state)
        for(let pregunta of props.location.state.preguntas)
        {
            if(pregunta.fecha_envio != null)
            {
                array.push(pregunta);
            }
        }
        setPreguntas(array);
    },[])

    const crear_word = async() => {
        
        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/word_historia"
        console.log("LIBRO ",libro._id)
        var data = {
            id: libro._id
        }

        fetch(url,{
            method: "POST",
            headers: {
                // "Content-Type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then((res)=>{
            return res.blob();
        }).then((res)=>{
            console.log("RES ",res)
            // const arr = new Uint8Array(res);
            // console.log("ARRAY ",arr)
            // const blob = new Blob([arr], { type: 'application/docx' });
            // console.log("BLOB ",blob)
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(res);
            link.download = 'historia-'+libro.nombre_autor+'_'+libro.apellido_autor+'.docx';
            link.click();
        })
    }

    return(
    <Fragment>
        <div className="font-serif overflow-y-hidden bg-[#F9F8F4] h-full">
            <NavBarAdmin id={'libros'} />

            <div className="flex justify-center">
                <div className="flex flex-row static w-11/12 justify-between h-40">
                    <div className="text-titulo">
                        Preguntas y respuestas
                    </div>
                    <div className="grid content-center">
                        <div className="flex flex-row pb-5">
                            <button onClick={()=>{crear_word()}} className="py-2 px-8 text-botones2 estilo-botones">
                                DESCARGAR
                            </button>                          
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-subtitulo -mt-5 pl-20 pb-10">
                PEDIDO {libro?.numero}
            </div> 
            {/* <div className="pl-24 text-[#504E4E]">
                    <div className="text-titulo-m">
                        Preguntas y respuestas
                    </div>
                    <br/>
                    <div className="text-subtitulo">
                        PEDIDO {libro?.numero}
                    </div>                       
                    <br/>
            </div> */}
            <div className="flex justify-center">
                <div className="w-11/12 max-h-128 overflow-y-auto">
                    <div className="table w-full">     
                        { /*         FILAS        */     }
                    
                        {
                            preguntas.map((pregunta)=>{
                                return(
                                    <div className="pt-0 pl-24 pr-24 pb-10">
                                        <h1 className="text-2xl pr-28 text-pregunta">{pregunta.numero}. {pregunta.pregunta}</h1>
                                        <h1 className="font-sans text-[#504E4E] text-respuesta py-5">
                                        {pregunta.respuesta}
                                        </h1>
                                        {/* <button 
                                        className="py-2 px-8 text-botones2 estilo-botones">
                                            VER MÁS
                                        </button> */}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            {/* <div className="pb-0 w-11/12 overflow-y-auto max-h-96">
            {
                preguntas.map((pregunta)=>{
                    return(
                        <div className="pt-0 pl-24 pr-24 pb-10">
                            <h1 className="text-2xl pr-28 text-pregunta">{pregunta.numero}. {pregunta.pregunta}</h1>
                            <h1 className="font-sans text-[#504E4E] text-respuesta py-5">
                            {pregunta.respuesta}
                            </h1>
                            <button 
                            className="py-2 px-8 text-botones2 estilo-botones">
                                VER MÁS
                            </button>
                        </div>
                    )
                })
            }
            </div> */}
        </div>
    </Fragment>
    );
}

export default PreguntasYRespuestas;