import React, {useState} from "react";
import XLSX from 'xlsx';
import NavBarAdmin from "../Components/NavbarAdmin";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { temasNP } from "../utils/OpcionesSelect";

var PrimeroDelAno = new Date(new Date().getFullYear(), 0, 1);
var hoy = new Date()
export default function FinanzasAdmin(props){
        const [fechaInicial, setFechaInicial] = useState()
        const [file, setFile] = useState(null);
        const [fechaFinal, setFechaFinal] = useState(hoy)

        const guardar_pregunta = (pregunta) => {
          var url = process.env.REACT_APP_HOST_URL+"api/v1/preguntas/"
          console.log("preguntaaaaaa",pregunta)
              var data = {
                pregunta : pregunta.pregunta.replaceAll(";",","),
                sexo: pregunta.sexo,
                temas: pregunta.temas,
                edad: pregunta.edad,
                estado_civil: pregunta.estado_civil,
                hijos: pregunta.hijos,
              } 
              console.log("datitaaaaaaaa",data)        
          fetch(url,{
              method:"POST",
              headers:{
                  "Content-Type": "application/json"
              },
              body: JSON.stringify(data)
          }).then(res => {    
                
          console.log("res",res)
          return res.json()  
          })
      }
        const filePathset = (e) => 
        {
            e.stopPropagation();
            e.preventDefault();
            setFile(e.target.files[0]);
        }
        
        const readFile = () => 
        {
            var f = file;
            // var name = f.name;
            const reader = new FileReader();
            reader.onload = (evt) => 
            {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                //console.log("Datita",data);
                console.log(convertToJson(data));
            };
            reader.readAsBinaryString(f);
        }

    // console.log("HOLABBYAQUECONTIGONOSIRVELALABIA",temasNP)
    // temasNP.map((hola)=>
    //   console.log("value",hola.value)
    //   )
      
      const limpieza_array = async(el,arr) => {
        //console.log("limpeiza",el)
        // console.log("arr antes",arr)
        var xd = arr.value[0]
        var xd2 = arr.label[0]
        // console.log("xddd",xd,xd2)
        arr.value.shift()
        arr.label.shift()
        //console.log("arr despues",arr)
        return [xd,xd2]

      }    


    const convertToJson = async(csv) => 
    {

        var lines = csv.split("\n");
    
        var result = [];
        var cont = 0;

        var testheaders = {}


        for (var i = 750; i < 766; i++) 
        {
          testheaders.sexo = []
          testheaders.estado_civil = []
          testheaders.temas = []
          testheaders.hijos = []
          testheaders.edad = []
          testheaders.pregunta = ""
            var currentline = lines[i].split(",");
            

            if(currentline[0] !== '')
            {
                console.log("currentline[0]",currentline[0]);
            }
            else
            {
                cont++;
            }
            console.log("currentline[0]!!!",currentline[0])
            // console.log("cont", cont) 

              for (var k = 0; k<currentline[0].split("; ").length; k++){
               let aleatorio = currentline[0].split("; ")
                testheaders.sexo.push({ // K=0   Masculino     ||        K=1  Femenino
                  label: aleatorio[k], value:aleatorio[k]
                })
              }

              for (var k = 0; k<currentline[1].split("; ").length; k++){
                let aleatorio = currentline[1].split("; ")
                 testheaders.estado_civil.push({ // K=0   Masculino     ||        K=1  Femenino
                   label: aleatorio[k], value:aleatorio[k]
                 })

               }
               var arrayBKN = []
               arrayBKN.value = []
               arrayBKN.label = []
               let aleatorio = currentline[3].split("; ")
               for (var m=0;m<temasNP.length;m++){
                for (var l=0;l<=aleatorio.length;l++){
                  if(temasNP[m].label === aleatorio[l]){
                    arrayBKN.value.push(temasNP[m].value)
                    arrayBKN.label.push(aleatorio[l])

                   // console.log("value",temasNP[m].value)
                   // console.log("aleatoriooo?",aleatorio[l],temasNP[m].label,temasNP[m].value)
                  }
                }
                
              }
              aleatorio = []
              // console.log("UU",arrayBKN.value)
               for (var k = 0; k<currentline[3].split("; ").length; k++){
                  var [value,label] = await limpieza_array(arrayBKN.value[k],arrayBKN)                  
                 testheaders.temas.push({ // TEMAS
                   label: label, value:value
                 })

               }
               for (var k = 0; k<currentline[4].split("; ").length; k++){
                let aleatorio = currentline[4].split("; ")
                 testheaders.edad.push({ // K=0   Masculino     ||        K=1  Femenino
                   label: aleatorio[k], value:aleatorio[k]
                 })

               }
               testheaders.pregunta = currentline[5]




              //  if(currentline[2] === 'Si'){
              //    testheaders.hijos = true
              //  }
              //  else{
              //    testheaders.hijos = false
              //  }
              for (var k = 0; k<currentline[2].split(";").length; k++){
                let aleatorio = currentline[2].split(";")
                 testheaders.hijos.push({ // K=0   Masculino     ||        K=1  Femenino
                   label: aleatorio[k], value:aleatorio[k]
                 })

               }              



                // obj[headers[j]] = currentline[j];
                result.push(testheaders);

              guardar_pregunta(testheaders)

              testheaders = {}
        }
        console.log("resultado",result);
    }

     return(
        <div className=" overflow-hidden bg-page w-full h-full">
                <NavBarAdmin id={'finanzas'} />

            <div className="flex justify-center">
            <div className="flex flex-row static w-11/12 mt-10 justify-start">
                <div className="text-titulo">
                    Descargar Finanzas
                </div>
                
               
            </div>


            </div>
            <div className="flex justify-center">
                <div className="w-11/12">
                
                    <div className="w-full grid grid-cols-3 gap-x-8">
                    {/*       Columna  1          */}
                        <div className="flex flex-col">
                        <label className="text-label">Sexo</label>
                        <select className="select-memo">
                          <option>Masculino</option>
                          <option value={'femenino'}>Femenino</option>
                        </select>

                        <label className="text-label mt-10">Procedencia</label>
                        <select className="select-memo">
                        <option value={'Regalo'}>Regalo</option>
                          <option value={'Propio'}>Propio</option>
                        </select>

                        <label className="text-label mt-10">Fecha Inicial</label>
                        <ReactDatePicker 
                        className="select-memo w-full"
                        dateFormat={'dd/MM/yyyy'}
                        selected={fechaInicial ? fechaInicial : PrimeroDelAno}
                        onChange={(date)=> setFechaFinal(date)}  />
                        </div>
                    {/*       Columna  2         */}
                        <div className="flex flex-col">
                        <label className="text-label">Estado de Libro</label>
                        <select className="select-memo">
                          <option>Finalizado</option>
                          <option>En desarrollo</option>
                          <option>Ambos</option>
                        </select>

                        <label className="text-label mt-10">Plan del cliente</label>
                        <select className="select-memo">
                          <option value={1}>Plan 50 Preguntas</option>
                          <option value={2}>Plan 75 Preguntas</option>
                          <option value={3}>Plan 100 Preguntas</option>
                        </select>

                        <label className="text-label mt-10">Fecha Final</label>
                        <ReactDatePicker 
                        className="select-memo w-full"
                        dateFormat={'dd/MM/yyyy'}
                        selected={fechaFinal ? fechaFinal : hoy}
                        onChange={(date)=> setFechaFinal(date)}
                        />
                        </div>
                        <div></div>
                    </div>
                    <button 
                     className="w-[537px] mt-12 h-[67px] estilo-botones text-botones">
                      DESCARGAR
                    </button>


                    {/* <input type="file" name="file" id="file" accept=".xls, .xlsX" onChange={(e) => filePathset(e)}/>
                    <button onClick={()=>{readFile()}} 
                     className="w-[537px] mt-12 h-[67px] estilo-botones text-botones">
                      SUBIR EXCEL
                    </button> */}
                    
                    
                </div>
            </div>
        </div>
        )

}