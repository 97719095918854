import { faImages} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Drawer from '../Components/Navbar';
import Swal from "sweetalert2";

function MiRespuesta(props){

    const historia = props.location.state ? props.location.state.historia : null
    const pregunta = props.location.state ? props.location.state.pregunta : null
    const [charN, setCharN] = useState(0)
    const [texto, setTexto] = useState(props.location.state ? props.location.state.pregunta.respuesta : null)

    useEffect(()=>{
        console.log("pregunta ",props.location.state)
    })

    function countChar(val) {
        var len = val.length;
        setCharN(len);
    }

    const validar_texto = async(borrador) => {
        if(texto.length > 0)
        {
            guardar_pregunta(borrador);
        }
        else
        {
            Swal.fire({
                title: 'Debe escribir una respuesta',
                icon: 'error',
                customClass:{
                    popup: 'swal2-border',
                    confirmButton: 'swal2-confirm',
                    title: 'text-label',
                    closeButton: 'swal2-closebtn',
                },
                confirmButtonText: 'VOLVER',
            })
        }
    }

    const guardar_pregunta = async(borrador) => {

        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/"+historia._id+"/responder/"+pregunta._id
        console.log("URL ",url)
        var data;
        if(borrador)
        {
            data = {
                respuesta: texto
            }
        }
        else
        {
            data = {
                respuesta: texto,
                estado: 1
            }
        }

        fetch(url,{
            method:"POST",
            headers:{
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res =>{
            return res.json()
        }).then(res => {
            if(borrador)
            {
                Alert2()
            }
            else
            {
                Alert1()
            }
            setTimeout(() => {
                window.location.href = "/mis_preguntas"
                }, 1000)
            console.log("RES ",res)
        })
    }

    const Toast = Swal.mixin({
        toast: true,
        width: '30%',
        heightAuto: false,
        showConfirmButton: false,
        position: 'top-right',
        timer: 1150,
        customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-navbar',
            closeButton: 'swal2-closebtn'
        }
    })

    const Alert1 = () => {Toast.fire({
        icon: 'success',
        title: 'Respuesta Guardada Exitosamente',

    })
    }
    const Alert2 = () => {Toast.fire({
        icon: 'success',
        title: 'Borrador Guardado!',
    })
    }

    return(
    <Fragment>
        <div className="font-serif overflow-y-hidden bg-[#F9F8F4] h-full">
            <Drawer id={4}/>
            <div className="pt-20 pl-24 text-[#504E4E] pb-5">
                    <h1 className="text-3xl text-titulo-m">Mi respuesta</h1>
                    <br/>
            </div>
            <div className="pb-0">
                <div className="pt-0 pl-24 pr-24 pb-0">
                    <h1 className="text-2xl pr-28 text-pregunta pb-5">{pregunta.pregunta}</h1>
                    <div>
                        {
                            pregunta.Estado != 1 && historia.vencido == false ? 
                            <textarea className="bg-[#FFFFFF64] w-full h-44 border-solid border-2 border-[#D1CAB8] p-4 font-sans text-[#504E4E]"
                            value={texto}
                            maxLength="1000"
                            onKeyUp={(e)=>countChar(e.target.value)}
                            onChange={(e)=>setTexto(e.target.value)}
                            >
                            </textarea>
                            :
                            <textarea disabled className="bg-[#FFFFFF64] w-full h-44 border-solid border-2 border-[#D1CAB8] p-4 font-sans text-[#504E4E]"
                            value={texto}
                            maxLength="1000"
                            onKeyUp={(e)=>countChar(e.target.value)}
                            onChange={(e)=>setTexto(e.target.value)}
                            >
                            </textarea>
                        }
                    </div>
                </div>
                <h1 className="font-sans text-xs pl-24 pt-2 text-[#717171]">{pregunta.Estado != 1 ? charN: texto.length}/1000 CARACTERES</h1>
            </div>
            <div className="flex justify-center">
                {
                    pregunta.Estado == 0 && historia.vencido == false? 
                    <div className="pt-8 pl-80">
                        <button 
                        onClick={()=>validar_texto(true)}
                        className="w-96 mr-6 px-12 py-2 text-botones estilo-botones">
                        GUARDAR BORRADOR
                        </button>
                        <button 
                        onClick={()=>validar_texto(false)}
                        className="w-96 px-12 py-2 text-botones estilo-botones">
                        ENVIAR RESPUESTA
                        </button>
                    </div>
                    :
                    null
                }
                
            </div>
        </div>
    </Fragment>
    );
}

export default MiRespuesta;