import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import logoPayku from '../assets/imgs/logo-payku-1.png';
import Swal from "sweetalert2";

function MetodoDePago(){

  var planData = JSON.parse(sessionStorage.getItem('planData')).plan;
  console.log("plandata",planData)
  var userData = JSON.parse(sessionStorage.getItem('userData'));
  console.log("nombre",userData.nombre)
  const copias = JSON.parse(sessionStorage.getItem('resumenCompraData'))
  console.log("copias",copias)
  console.log("2",JSON.parse(sessionStorage.getItem('planData')).plan);
  var correoRegalo = JSON.parse(sessionStorage.getItem('emailData'))
  console.log("4",JSON.parse(sessionStorage.getItem('personalizarLibroData')));
  var personalizarLibroData= JSON.parse(sessionStorage.getItem('personalizarLibroData'))


  const Toast = Swal.mixin({
    width: '40%',
    customClass:{
        popup: 'swal2-border',
        confirmButton: 'swal2-confirm',
        title: 'text-label',
        closeButton: 'swal2-closebtn'}
})


  var horari0 = new Date(personalizarLibroData.horario)
  console.log("horarioxd",horari0)



  const crear_usuario = () => {
    
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users"

    var data = {
      nombre: userData.nombre,
      apellido: userData.apellido,
      ciudadCrecimiento: userData.ciudadCrecimiento,
      ocupacion: userData.ocupacion,
      correo: userData.correo,
      edad: userData.edad,
      paisCrecimiento: userData.paisCrecimiento,
      sexo: userData.sexo,
      telefono: userData.telefono,
      estadoCivil: userData.estadoCivil,
    }
 
    fetch(url,{
      method: "POST",
      headers:{
        "Content-type": "application/json",
        // "x-access-token": Auth.getToken()
    },
    body: JSON.stringify(data)
    }).then((res)=>{
      console.log("res1",res)
      if (res.status == 400){
        Toast.fire({
          title: 'Ha ocurrido un error!',
          icon: 'error'
        })
      }
      if (res.status == 201){
       console.log("éxito!");
       unir_historia()
      }
      }).then((res)=> {
        console.log("res2",res)
      })
    
  }



  // faltan horas
  const unir_historia = () => {

    var url = process.env.REACT_APP_HOST_URL+"api/v1/historias"

    var data = {
      plan: '61e736e3b345cf81d57a4904',
      numero_de_copias: copias.copias,
      horas_asesoramiento: copias.profesional,
      preguntas_por_semana: personalizarLibroData.preguntas,
      dias_para_enviar: {
        Lunes: personalizarLibroData.lunes,
        Martes: personalizarLibroData.martes,
        Miercoles: personalizarLibroData.miercoles,
        Jueves: personalizarLibroData.jueves,
        Viernes: personalizarLibroData.viernes,
        Sabado: personalizarLibroData.sabado,
        Domingo: personalizarLibroData.domingo
      },
      correo_quien_paga: correoRegalo,
      correo_usuario: userData.correo,
      nombre_autor: userData.nombre,
      apellido_autor: userData.apellido,
      quien_escribe: correoRegalo ? "otra_persona" : "yo"
      
    }
 
    fetch(url,{
      method: "POST",
      headers:{
        "Content-type": "application/json",
        // "x-access-token": Auth.getToken()
    },
    body: JSON.stringify(data)
    }).then((res)=>{
      console.log("res1",res)
      })

  }











  return(
    <Fragment>
      <div className="bg-page  h-full w-full flex flex-col 
      text-center place-items-center md:flex-wrap space-y-16">
      {/*                           TITULO                                */}
        <div className="text-titulo pt-16">Método de pago</div>

      {/*                           CUADRO                                */}
        <div className="flex flex-col bg-white hover:border-[#717171] border-[#D1CAB8] 
        font-sans border-2">
          <div className="flex flex-row space-x-4 p-2">
            <div className="justify-center items-center align-middle">
              <input type={'radio'} className="mt-6"/>
            </div>
            <img src={logoPayku} className="h-16 w-40"/>
            <div className="flex flex-col text-left pt-2">
              <div className="font-bold">Payku: Paga con tu banco</div>
              <div>Utiliza tu banco, simplifica tus transferencias</div>
            </div>
          </div>
        </div>
      {/*                           BOTONES                               */}
        <div className="flex flex-row space-x-4">
          <Link to={'/confirmacion_de_compra'}>
            <button 
            className=" text-botones2 estilo-botones py-2 px-16 ">
              VOLVER
            </button>
          </Link>
         
            <button onClick={crear_usuario}
            className="text-botones2 estilo-botones py-2 px-12">
              CONTINUAR
            </button>

          
        </div>
      </div>
    </Fragment>
  )
};

export default MetodoDePago;