import React, {useEffect} from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import libro1 from '../assets/imgs/libro1.png'
import Drawer from '../Components/Navbar';

function MiPlan(){

    const load_plan = () => {

    }

useEffect(() => {
    load_plan()
})



    return(
    <Fragment>
        <div className=" overflow-y-hidden bg-page w-full h-full">
            <Drawer id={3}/>

            <div className='flex justify-center'>
                <div className='w-11/12'>
                <div className="pt-2">
                    <div className=" text-titulo">Mi Plan</div>
                    <h1 className="font-cormorant font-semibold text-letra1 text-[50px] -mt-8 mb-12">48 preguntas</h1>
                </div>
            <div className="grid grid-cols-3 gap-3 text-[#504E4E]">
                <div className="text-label pb-0">
                    <h1>Portada seleccionada:</h1> 
                </div>
                <div className="columna2 -mt-24 -ml-6">

                    <img src={libro1} width="85%" />
                    </div>
                    <div className="columna3">
                    <div className="flex flex-row">
                            <div className="text-label">
                            Cantidad de preguntas:
                            </div>
                            <div className="pl-2 text-botones2 text-[28px] pt-1">48</div>
                        </div>
                        
                    <div className="flex flex-row">
                            <div className="text-label">
                            Número de páginas:
                            </div>
                            <div className="pl-2 text-botones2 text-[28px]  pt-1">60 </div>
                        </div>
                       
                        <div className="flex flex-row">
                            <div className="text-label">
                            Edición:
                            </div>
                            <div className="pl-2 text-botones2 text-[28px]  pt-1"></div>
                        </div>
                        <div className="flex flex-row">
                            <div className="text-label">
                            Fotografías:
                            </div>
                            <div className="pl-2 text-botones2 text-[28px] pt-1">Sin Fotografías</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="text-label">
                            Ejemplares:
                            </div>
                            <div className="pl-2 text-botones2 text-[28px] pt-1">1 Copia</div>
                        </div>
                        
                        <button 
                        className="py-2 mt-8 px-8 text-botones2 w-4/5 tracking-wide estilo-botones">
                        AMPLIAR EL PLAN
                        </button>

                    </div>
            </div>
            <div className="grid grid-cols-3 gap-3">
                <div></div>
                <div></div>
                <div className="pt-20">
                    
                        <button 
                        className="py-2 px-8 text-botones2 w-4/5 tracking-wide estilo-botones">
                        AMPLIAR EL PLAN
                        </button>
                </div>
            </div>
        </div>
        </div>
        </div>
    </Fragment>
    );
}

export default MiPlan;