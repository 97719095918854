import React,{Fragment, useEffect} from 'react'
import { Link } from "react-router-dom";
import Logo from "../assets/imgs/logo negro.png"
import NavBarPrincipal from '../Components/NavbarPrincipal';

function Inicio(){
  const userLoged = JSON.parse(localStorage.getItem("memoria_creativa_user")) != null ?  JSON.parse(localStorage.getItem("memoria_creativa_user")):null
  useEffect(()=>{
    // localStorage.clear()
  },[])

  return (
    <Fragment>
      <div className="overflow-x-hidden bg-page w-full h-full">
        {/* <NavBarPrincipal id={1}/> */}
        <div className="flex flex-col place-items-center bg-page text-[#504E4E] h-screen mx-auto">
          <div className="pt-[4%]">
            <img className="h-[267px] w-[288px]" src={Logo}/>
          </div>
          <div className="text-center font-cormorant font-medium text-[100px] text-[#504E4E]  pb-[2%]">
            Mi Memoria Creativa
          </div>
          <div className='text-[50px] font-cormorant font-medium -mt-20 mb-4'>Escribe tus recuerdos a partir de preguntas en torno a tu vida</div>

          <div className='font-cormorant text-[30px] text-center text-letra1'> Te acompañamos semana a semana para que recuerdes y escribas los grandes momentos de tu historia. <br/>
          Al final del recorrido, Memoria Creativa los inmortalizará en un libro. <br/></div>
          <div className='font-cormorant text-[30px] text-center mb-12'>Esta experiencia puede ser para ti o puede ser un regalo.</div>
          <div className='bg-page flex justify-between'>
          <Link to={{ pathname: "/planes", state: { regalo: false}}}>
              <button 
              className="estilo-botones text-botones px-10 w-[452px] mr-20 h-[67px]">
                PARA MÍ 
              </button>
            </Link>
            <Link to={{ pathname: "/planes", state: { regalo: true}}}>
              <button 
              className="estilo-botones text-botones px-10 w-[452px] h-[67px]">
                PARA REGALAR
              </button>
            </Link>
          </div>
          
          <div className='bg-page w-1/3 pt-4 flex flex-row justify-between'>
            {
              userLoged && !userLoged.esAdmin ?
              <Link to={'/mi_libro'}>
                <div className="mt-8 text-botones2">INGRESAR USUARIO</div>
              </Link>
              :
              <Link to={'/login'}>
                <div className="mt-8 text-botones2">INGRESAR USUARIO</div>
              </Link>
            }
            {
              userLoged && userLoged.esAdmin ?
              <Link to={'/libros_admin'}>
                <div className="mt-8 text-botones2">INGRESAR ADMINISTRADOR</div>
              </Link>
              :
              <Link to={'/login_admin'}>
                <div className="mt-8 text-botones2">INGRESAR ADMINISTRADOR</div>
              </Link>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Inicio;