/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Fragment } from "react/cjs/react.production.min";
import logoMemoria from "../assets/imgs/logo memoria_1.png"
import Swal from "sweetalert2";

function NavBarPrincipal(props)
{
    const cerrarSesion = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            icon: 'info',
            customClass:{
              popup: 'swal2-border',
              confirmButton: 'swal2-confirm',
              title: 'text-label',
              closeButton: 'swal2-closebtn',
            },
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true
        }).then((res)=>{
            if(res.isConfirmed){
                Swal.close()
                localStorage.removeItem("memoria_creativa_user");
                window.location.href = '/inicio'
            }})
    }

    return(
        <Fragment>
        <div className="flex justify-center">
            <nav className="bg-page pt-16 w-11/12">
                <div className="flex flex-wrap justify-between mr-8">
                    <a href="/" className="flex">
                        <img className="h-[62px] w-[156px] mb-8" src={logoMemoria}></img>
                    </a>
                    <button data-collapse-toggle="mobile-menu" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                        <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                    <div className="hidden w-full md:block md:w-auto" id="mobile-menu">
                        <ul className="flex flex-col mt-4 md:flex-row md:space-x-4 md:mt-0 text-navbar">
                            {
                                props.id === 1 ?
                                <li>
                                    <a href="/inicio" className="border-b-2 border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:text-[#D05A3D] md:p-0 dark:text-white" aria-current="page">Home</a>
                                </li>
                                :
                                <li>
                                    <a href="/inicio" className="hover:border-b-2 hover:text-[#D05A3D] text-[#504E4E] border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:p-0 dark:text-white">Home</a>
                                </li>
                            }
                            <div className="hidden md:block">-</div>
                            {
                                props.id === 2 ?
                                <li>
                                    <a href="/quienes_somos" className="border-b-2 border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:text-[#D05A3D] md:p-0 dark:text-white" aria-current="page">Quiénes somos</a>
                                </li>
                                :
                                <li>
                                    <a href="/quienes_somos" className="hover:border-b-2 hover:text-[#D05A3D] text-[#504E4E] border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:p-0 dark:text-white">Quiénes somos</a>
                                </li>
                            }
                            <div className="hidden md:block">-</div>
                            {
                                props.id === 3 ?
                                <li>
                                    <a href="/preguntas_frecuentes" className="border-b-2 border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:text-[#D05A3D] md:p-0 dark:text-white" aria-current="page">Preguntas frecuentes</a>
                                </li>
                                :
                                <li>
                                    <a href="/preguntas_frecuentes" className="hover:border-b-2 hover:text-[#D05A3D] text-[#504E4E] border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:p-0 dark:text-white">Preguntas frecuentes</a>
                                </li>
                            }
                            <div className="hidden md:block">-</div>
                            {
                                props.id === 4 ?
                                <li>
                                    <a href="/terminos_y_condiciones" className="border-b-2 border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:text-[#D05A3D] md:p-0 dark:text-white" aria-current="page">Términos y condiciones</a>
                                </li>
                                :
                                <li>
                                    <a href="/terminos_y_condiciones" className="hover:border-b-2 hover:text-[#D05A3D] text-[#504E4E] border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:p-0 dark:text-white">Términos y condiciones</a>
                                </li>
                            }
                            <div className="hidden md:block">-</div>
                            {
                                props.id === 5 ?
                                <li>
                                    <a href="/contacto" className="border-b-2 border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:text-[#D05A3D] md:p-0 dark:text-white" aria-current="page">Contáctanos</a>
                                </li>
                                :
                                <li>
                                    <a href="/contacto" className="hover:border-b-2 hover:text-[#D05A3D] text-[#504E4E] border-[#D05A3D] block py-2 pr-2 pl-2 md:bg-transparent md:p-0 dark:text-white">Contáctanos</a>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        </Fragment>
    )
}

export default NavBarPrincipal;