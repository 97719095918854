import React, {Fragment} from "react";
import {BrowserRouter as Router, Routes, Route, Redirect, Switch, HashRouter } from 'react-router-dom';
import EleccionPersona from "./Pages/EleccionPersona";
import CambiarClave from "./Pages/CambiarClave";
import Inicio from './Pages/Inicio';
import Login from "./Pages/Login";
import MiLibro from "./Pages/MiLibro";
import MiPerfil from "./Pages/MiPerfil";
import MiPlan from "./Pages/MiPlan";
import MisPreguntas from "./Pages/MisPreguntas";
import EditarPregunta from "./Pages/EditarPregunta";
import NuevaPregunta from "./Pages/NuevaPregunta";
import Planes from "./Pages/Planes";
import CrearMiPerfil from "./Pages/CrearMiPerfil";
import CrearSuPerfil from "./Pages/CrearSuPerfil";
import PersonalizarLibro from "./Pages/PersonalizarLibro";
import TemasDeInteres from './Pages/TemasDeInteres';
import ResumenDeCompra from './Pages/ResumenDeCompra';
import MiRespuesta from "./Pages/MiRespuesta";
import PreguntasYRespuestas from "./Pages/PreguntasYRespuestas";
import MetodoDePago from "./Pages/MetodoDePago";
import ConfirmacionCompra from "./Pages/ConfirmacionCompra";
import LoginAdmin from "./Pages/LoginAdmin";
import LibrosAdmin from './Pages/LibrosAdmin';
import PreguntasAdmin from "./Pages/PreguntasAdmin";
import FinanzasAdmin from "./Pages/FinanzasAdmin";
import PerfilUsuarioAdmin from "./Pages/PerfilUsuarioAdmin";
import Comprobante from "./Pages/Comprobante"
import PagoExitoso from "./Pages/PagoExitoso"
import EditarPerfilUser from "./Pages/EditarPerfilUser";
import ConfirmacionCorreo from './Pages/ConfirmacionCorreo'
import CambiarClave2 from "./Pages/CambiarClave2";
import QuienesSomos from "./Pages/QuienesSomos";
import PreguntasFrecuentes from "./Pages/PreguntasFrecuentes";
import TerminosCondiciones from "./Pages/TerminosCondiciones";
import Contacto from "./Pages/Contacto";

const Child = (props)=>
{
  console.log(props)
  const userLoged = JSON.parse(localStorage.getItem("memoria_creativa_user"))

  if(localStorage.getItem("memoria_creativa_user"))
  {
    if(userLoged.esAdmin)
    {
      if(props.match.params.id === "login") return  <Login {...props}/>
      if(props.match.params.id === "planes") return  <Planes {...props}/>
      if(props.match.params.id === "crear_mi_perfil") return  <CrearMiPerfil {...props}/>
      if(props.match.params.id === "crear_su_perfil") return  <CrearSuPerfil {...props}/>
      if(props.match.params.id === "temas_interes") return  <TemasDeInteres {...props}/>
      if(props.match.params.id === "resumen_compra") return  <ResumenDeCompra {...props}/>
      if(props.match.params.id === "cambiar_clave") return  <CambiarClave {...props}/>
      if(props.match.params.id === "cambiar_clave2") return  <CambiarClave2 {...props}/>
      if(props.match.params.id === "inicio") return <Inicio {...props}/>
      if(props.match.params.id === "metodo_pago") return <MetodoDePago {...props}/>
      if(props.match.params.id === "pago_exitoso") return <PagoExitoso {...props}/>
      if(props.match.params.id === "confirmacion_de_compra") return  <ConfirmacionCompra {...props}/>
      if(props.match.params.id === "login_admin") return  <LoginAdmin {...props}/>
      if(props.match.params.id === "comprobar_correo") return  <ConfirmacionCorreo {...props}/>
      if(props.match.params.id === "libros_admin") return  <LibrosAdmin {...props}/>
      if(props.match.params.id === "preguntas_admin") return  <PreguntasAdmin {...props}/>
      if(props.match.params.id === "finanzas_admin") return  <FinanzasAdmin {...props}/>
      if(props.match.params.id === "perfil_usuario_admin") return  <PerfilUsuarioAdmin {...props}/>
      if(props.match.params.id === "preguntas_y_respuestas") return  <PreguntasYRespuestas {...props}/>
      if(props.match.params.id === "nueva_pregunta") return  <NuevaPregunta {...props}/>
      if(props.match.params.id === "editar_pregunta") return  <EditarPregunta {...props}/>
      if(props.match.params.id === "comprobante_de_compra") return  <Comprobante {...props}/>
      if(props.match.params.id === "quienes_somos") return  <QuienesSomos {...props}/>
      if(props.match.params.id === "preguntas_frecuentes") return  <PreguntasFrecuentes {...props}/>
      if(props.match.params.id === "terminos_y_condiciones") return  <TerminosCondiciones {...props}/>
      if(props.match.params.id === "contacto") return  <Contacto {...props}/>
    else return <Redirect to="inicio" />
    }
    else if(userLoged)
    {
      if(props.match.params.id === "login") return  <Login {...props}/>
      if(props.match.params.id === "planes") return  <Planes {...props}/>
      if(props.match.params.id === "crear_mi_perfil") return  <CrearMiPerfil {...props}/>
      if(props.match.params.id === "crear_su_perfil") return  <CrearSuPerfil {...props}/>
      if(props.match.params.id === "temas_interes") return  <TemasDeInteres {...props}/>
      if(props.match.params.id === "resumen_compra") return  <ResumenDeCompra {...props}/>
      if(props.match.params.id === "cambiar_clave") return  <CambiarClave {...props}/>
      if(props.match.params.id === "cambiar_clave2") return  <CambiarClave2 {...props}/>
      if(props.match.params.id === "inicio") return <Inicio {...props}/>
      if(props.match.params.id === "metodo_pago") return <MetodoDePago {...props}/>
      if(props.match.params.id === "pago_exitoso") return <PagoExitoso {...props}/>
      if(props.match.params.id === "confirmacion_de_compra") return  <ConfirmacionCompra {...props}/>
      if(props.match.params.id === "login_admin") return  <LoginAdmin {...props}/>
      if(props.match.params.id === "comprobar_correo") return  <ConfirmacionCorreo {...props}/>
      if(props.match.params.id === "mi_libro") return  <MiLibro {...props}/>
      if(props.match.params.id === "mi_perfil") return  <MiPerfil {...props}/>
      if(props.match.params.id === "login_admin") return  <LoginAdmin {...props}/>
      if(props.match.params.id === "mis_preguntas") return  <MisPreguntas {...props}/>
      if(props.match.params.id === "mi_respuesta") return  <MiRespuesta {...props}/>
      if(props.match.params.id === "preguntas_y_respuestas") return  <PreguntasYRespuestas {...props}/>
      if(props.match.params.id === "editar_perfil") return <EditarPerfilUser {...props}/>
      if(props.match.params.id === "quienes_somos") return  <QuienesSomos {...props}/>
      if(props.match.params.id === "preguntas_frecuentes") return  <PreguntasFrecuentes {...props}/>
      if(props.match.params.id === "terminos_y_condiciones") return  <TerminosCondiciones {...props}/>
      if(props.match.params.id === "contacto") return  <Contacto {...props}/>
      else return <Redirect to="inicio"/>
    }
  }
  else
  {
    if(props.match.params.id === "login") return  <Login {...props}/>
    if(props.match.params.id === "planes") return  <Planes {...props}/>
    if(props.match.params.id === "crear_mi_perfil") return  <CrearMiPerfil {...props}/>
    if(props.match.params.id === "crear_su_perfil") return  <CrearSuPerfil {...props}/>
    if(props.match.params.id === "temas_interes") return  <TemasDeInteres {...props}/>
    if(props.match.params.id === "resumen_compra") return  <ResumenDeCompra {...props}/>
    if(props.match.params.id === "cambiar_clave") return  <CambiarClave {...props}/>
    if(props.match.params.id === "cambiar_clave2") return  <CambiarClave2 {...props}/>
    if(props.match.params.id === "inicio") return <Inicio {...props}/>
    if(props.match.params.id === "metodo_pago") return <MetodoDePago {...props}/>
    if(props.match.params.id === "pago_exitoso") return <PagoExitoso {...props}/>
    if(props.match.params.id === "confirmacion_de_compra") return  <ConfirmacionCompra {...props}/>
    if(props.match.params.id === "login_admin") return  <LoginAdmin {...props}/>
    if(props.match.params.id === "comprobar_correo") return  <ConfirmacionCorreo {...props}/>
    if(props.match.params.id === "quienes_somos") return  <QuienesSomos {...props}/>
    if(props.match.params.id === "preguntas_frecuentes") return  <PreguntasFrecuentes {...props}/>
    if(props.match.params.id === "terminos_y_condiciones") return  <TerminosCondiciones {...props}/>
    if(props.match.params.id === "contacto") return  <Contacto {...props}/>

    //if(props.match.params.id === "libros_admin") return  <LibrosAdmin {...props}/>
    //if(props.match.params.id === "preguntas_admin") return  <PreguntasAdmin {...props}/>
    //if(props.match.params.id === "finanzas_admin") return  <FinanzasAdmin {...props}/>
    //if(props.match.params.id === "perfil_usuario_admin") return  <PerfilUsuarioAdmin {...props}/>
    //if(props.match.params.id === "editar_perfil") return <EditarPerfilUser {...props} />
    //if(props.match.params.id === "eleccion_persona") return  <EleccionPersona {...props}/>
    //if(props.match.params.id === "personalizar_libro") return  <PersonalizarLibro {...props}/>
    //if(props.match.params.id === "cambiar_clave2") return  <CambiarClave2 {...props}/>
    //if(props.match.params.id === "mi_libro") return  <MiLibro {...props}/>
    //if(props.match.params.id === "mi_perfil") return  <MiPerfil {...props}/>
    //if(props.match.params.id === "mi_plan") return  <MiPlan {...props}/>
    //if(props.match.params.id === "mis_preguntas") return  <MisPreguntas {...props}/>
    //if(props.match.params.id === "editar_pregunta") return  <EditarPregunta {...props}/>
    //if(props.match.params.id === "nueva_pregunta") return  <NuevaPregunta {...props}/>
    //if(props.match.params.id === "mi_respuesta") return  <MiRespuesta {...props}/>
    //if(props.match.params.id === "preguntas_y_respuestas") return  <PreguntasYRespuestas {...props}/>
    //if(props.match.params.id === "comprobante_de_compra") return  <Comprobante {...props}/>
    
    // if(props.match.params.id === "login") return  <MiPerfil {...props}/>
    else return <Redirect to="inicio"/>
  }
}

function App()
{
  return(
    <Router>
      <Switch>
        <Fragment>
          <Route exact path='/:id' render={Child}/>
          <Route exact path='/' render={Child}/>
        </Fragment>
      </Switch>
    </Router>
  )
}

// function App(){
//   return (
//     <HashRouter>
//       <Switch>
//         {/* <Redirect path="/**" to="/" /> */}
//         <Route path={'/'} element={<Inicio/>}/>
//         <Route path={'/login'} render={()=>{return userLoged != null ? <miLibro/> : <Login/>}}/>
//         <Route path={'/planes'} element={<Planes/>}/>
//         <Route path={'/eleccion_persona'} element={<EleccionPersona/>}/>
//         <Route path={'/crear_mi_perfil'} element={<CrearMiPerfil/>}/>
//         <Route path={'/crear_su_perfil'} element={<CrearSuPerfil/>}/>
//         <Route path={'/personalizar_libro'} element={<PersonalizarLibro/>}/>
//         <Route path={'/personalizar_libro'} element={<PersonalizarLibro/>}/>
//         <Route path={'/temas_interes'} element={<TemasDeInteres />} />
//         <Route path={'/resumen_compra'} element={<ResumenDeCompra />} />
//         <Route path={'/cambiarClave'} element={<CambiarClave/>}/>
//         {/* <Route path={'/mi_libro'} element={<MiLibro/>}/> */}
//         <Route path={'/miPerfil'} element={<MiPerfil/>}/>
//         <Route path={'/miPlan'} element={<MiPlan/>}/>
//         <Route path={'/misPreguntas'} element={<MisPreguntas/>}/>
//         <Route path={'/miRespuesta'} element={<MiRespuesta/>}/>
//       </Switch>
//     </HashRouter>
//   );
// }

export default App;