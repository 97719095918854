import React, {useState} from "react";
import {Fragment} from "react";
import Drawer from '../Components/Navbar';
import Swal from "sweetalert2";
import { CustomStyle, CustomTheme, sexosNP, edades, estadosNP, temasNP, opcionCorta, temas } from "../utils/OpcionesSelect";
import Select from "react-select";
import NavBarAdmin from "../Components/NavbarAdmin";



function NuevaPregunta(){
    const [charN, setCharN] = useState("")
    const [texto, setTexto] = useState("")
    const [sexo, setSexo] = useState(null);
    const [tema, setTema] = useState(null);
    const [hijos, setHijos] = useState(null);
    const [nietos, setNietos] = useState(null);
    const [estudios, setEstudios] = useState(null);
    // const [edad, setEdad] = useState(null);
    const [estadoCivil, setEstadoCivil] = useState(null);

    const Toast = Swal.mixin({
        toast: true,
        width: '30%',
        heightAuto: false,
        showConfirmButton: false,
        position: 'top-right',
        timer: 1150,
        customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-navbar',
            closeButton: 'swal2-closebtn'
        }
    })
    const Alert1 = () => {Toast.fire({
        icon: 'success',
        title: 'Pregunta Creada exitosamente',

    })
    }
    const Alert2 = () => {Toast.fire({
        icon: 'success',
        title: 'Borrador Creado!',
    })
    }


    function countChar(val) {
        var len = val.length;
        setCharN(len);
    }

    const guardar_pregunta = (borrador) => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/preguntas/"
        var data;
        if(borrador)
        {
            data = {
                pregunta : texto,
                sexo: sexo,
                temas: tema,
                nietos: nietos,
                estudios_universitarios: estudios,
                // edad: edad,
                estado_civil: estadoCivil,
                hijos: hijos,
            }
        }
        else
        {
            data = {
                pregunta : texto,
                sexo: sexo,
                temas: tema,
                nietos: nietos,
                estudios_universitarios: estudios,
                // edad: edad,
                estado_civil: estadoCivil,
                hijos: hijos,
                estado: true
            }
        }

        console.log("data", data)
        fetch(url,{
            method:"POST",
            headers:{
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        }).then(res => {
        if(borrador){
        Alert2()
        }
        else{Alert1()}
        setTimeout(() => {
            window.location.href = "/preguntas_admin"
            }, 1000)
        console.log("res",res)
        return res.json()
        })
    }

    return(
    <Fragment>
        <div className="font-serif overflow-y-auto overflow-x-hidden bg-page h-full">
            {/* <Drawer id={4}/> */}
            <NavBarAdmin id={'preguntas'} />
            <div className="pt-20 pb-10 pl-24 text-[#504E4E]">
                    <h1 className="text-3xl text-titulo-m">Nueva pregunta</h1>
                    <br/>
            </div>
            <div className="pb-0">
                <div className="pt-0 pl-24 pr-24 pb-0">
                    <textarea className="bg-[#FFFFFF64] w-full h-44 border-solid border-2 border-[#D1CAB8] p-4 font-sans text-[#504E4E]"
                    value={texto}
                    maxLength="150"
                    onKeyUp={(e)=>countChar(e.target.value)}
                    onChange={(e)=>setTexto(e.target.value)}
                    >
                        
                    </textarea>
                </div>
                <h1 className="font-sans text-xs pl-24 pt-2 text-[#717171]">{charN}/150 CARACTERES</h1>
            </div>


            <div className="grid grid-cols-3 gap-5 text-[#504E4E] pt-10 px-24">
                <div className="flex flex-col">
                <label className="text-label ">Tema</label>
                    <Select placeholder="Selecciona" options={temasNP} theme={CustomTheme} defaultValue={tema}
                onChange={setTema} className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>

                <label className="text-label mt-10">Sexo</label>
                <Select placeholder="Selecciona" options={sexosNP} theme={CustomTheme} defaultValue={sexo}
                    onChange={setSexo} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"} />

                </div>

                <div className="flex flex-col">
                    <label className="text-label">Estado civil</label>
                    <Select placeholder="Selecciona" options={estadosNP} theme={CustomTheme} defaultValue={estadoCivil}
                    onChange={setEstadoCivil} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>
                    <label className="text-label mt-10">Estudios universitarios</label>
                    <Select placeholder="Selecciona" options={opcionCorta} theme={CustomTheme} defaultValue={estudios}
                    onChange={setEstudios} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>
                    {/* <label className="text-label mt-10">Edad</label>
                    <Select options={edades} theme={CustomTheme} defaultValue={edad}
                        onChange={setEdad} isMulti className="select-memo" styles={CustomStyle}/> */}
                </div>

                <div className="flex flex-col">
                    <label className="text-label">Hijos</label>
                    <Select placeholder="Selecciona" options={opcionCorta} theme={CustomTheme} defaultValue={hijos}
                    onChange={setHijos} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>
                    {/* <select className="select-memo"
                    onChange={(e)=> setHijos(e.target.value)}>
                        <option value={null}>Todos</option>
                        <option value={false}>No</option>
                        <option value={true}>Sí</option>
                    </select> */}
                    <label className="text-label mt-10">Nietos</label>
                    <Select placeholder="Selecciona" options={opcionCorta} theme={CustomTheme} defaultValue={nietos}
                    onChange={setNietos} isMulti className="select-memo" styles={CustomStyle} noOptionsMessage={() => "No quedan más opciones"}/>
                    {/* <select className="select-memo"
                    onChange={(e)=> setNietos(e.target.value)}>
                        <option value={null}>Todos</option>
                        <option value={false}>No</option>
                        <option value={true}>Sí</option>
                    </select> */}
                </div>
            </div>
            <div className="grid grid-cols-2 gap-2 text-[#504E4E]">
                <div className="pl-24 pt-4 font-sans">
                    
                </div>
                <div className="pt-8">
                    
                        <button onClick={() => guardar_pregunta(true)}
                        className="text-botones2 py-2 px-12 estilo-botones">
                        GUARDAR BORRADOR
                        </button>

                    <button 
                    onClick={()=>guardar_pregunta(false)}
                    className="text-botones2 estilo-botones py-2 px-6 ml-6">
                    GUARDAR PREGUNTA
                    </button>
                </div>
            </div>
        </div>
    </Fragment>
    );
}

export default NuevaPregunta;