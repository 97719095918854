import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import memoLogo from '../assets/imgs/logo memoria_1.png';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import iconoerror from '../assets/iconos/error.png'
import { temas } from "../utils/OpcionesSelect";

function PagoExitoso(){
  const [order,setOrder] = useState("")
  const [idTransaccion, setIdTransaccion] = useState("")
  const [status, setStatus] = useState("")
  const [email, setEmail] = useState("")
  const [monto, setMonto] = useState("")
  const [pagina,setPagina] = useState(null)


  var planData = JSON.parse(sessionStorage.getItem('planData')).plan;
  console.log("plandata",planData)
  var userData = JSON.parse(sessionStorage.getItem('userData'));
  console.log("nombre",userData.nombre)
  console.log("2",JSON.parse(sessionStorage.getItem('planData')).plan);
  var correoRegalo = JSON.parse(sessionStorage.getItem('emailData'))
  console.log("4",JSON.parse(sessionStorage.getItem('personalizarLibroData')));
  var personalizarLibroData= JSON.parse(sessionStorage.getItem('personalizarLibroData'))
  var temas = JSON.parse(sessionStorage.getItem('temasInteresData'));
  console.log("temitas",temas)
  var paykuID = sessionStorage.getItem('paykuID').replaceAll('"','')
  console.log("ID transsacción",paykuID)


  const Toast = Swal.mixin({
    width: '40%',
    customClass:{
        popup: 'swal2-border',
        confirmButton: 'swal2-confirm',
        title: 'text-label',
        closeButton: 'swal2-closebtn'}
})


useEffect(() => {
  console.log("esto está imprimiendo esto esto esto")
  console.log("hay que ver las respuestas")
  verificarTransaccion()  
},[])





const verificarTransaccion = () =>
    {
        console.log("id de la transaccion verificar transaccion", paykuID)
        var url = "https://app.payku.cl/api/transaction/"+paykuID
        console.log("url verificar transaccion", url)
        fetch(url,{
            method: "GET",
            headers: {
                // "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer tkpu82ca4b8579c070d34edd9abd1561"
            },
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            console.log("resultado fetch verificar transaccion payku",res)
            setEmail(res.email)
            setOrder(res.order)
            setMonto(res.amount)
            setIdTransaccion(res.id)
            setStatus(res.status)

            if(res.status === 'success'){
                  guardar_payku(res.email,res.order,res.amount,res.id,res.status)
                  setPagina(true)

            }else{
              setPagina(false)
            }

        })
    }

  const crear_usuario = () => {
    
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users"

    var data = {
      nombre: userData.nombre,
      apellido: userData.apellido,
      estudios: userData.estudios,
      correo: userData.correo,
      edad: userData.edad,
      hijos: userData.hijos,
      nietos: userData.nietos,
      // paisCrecimiento: userData.paisCrecimiento,
      sexo: userData.sexo,
      telefono: userData.telefono,
      estadoCivil: userData.estadoCivil,
    }
    // var data = {
    //   nombre: userData.nombre,
    //   apellido: userData.apellido,
    //   ocupacion: userData.ocupacion,
    //   correo: userData.correo,
    //   edad: userData.edad,
    //   hijos: userData.hijos,
    //   paisCrecimiento: userData.paisCrecimiento,
    //   sexo: userData.sexo,
    //   telefono: userData.telefono,
    //   estadoCivil: userData.estadoCivil,
    // }
    console.log("datita",data)
    fetch(url,{
      method: "POST",
      headers:{
        "Content-type": "application/json",
        // "x-access-token": Auth.getToken()
    },
    body: JSON.stringify(data)
    }).then((res)=>{
      console.log("res1",res)
      if (res.status == 400){
        Toast.fire({
          title: 'Ha ocurrido un error!',
          icon: 'error'
        })
      }
      if(res.status == 201 || res.status == 200)
      {
        console.log("éxito!");
        unir_historia()
      }
      }).then((res)=> {
        console.log("res2",res)
      })
    
  }



  // faltan horas
  const unir_historia = () => {

    var url = process.env.REACT_APP_HOST_URL+"api/v1/historias"

    var data = {
      plan: '61e736e3b345cf81d57a4904',
      //numero_de_copias: copias.copias,
      //horas_asesoramiento: copias.profesional,
     // preguntas_por_semana: personalizarLibroData.preguntas,
      // dias_para_enviar: {
      //   Lunes: personalizarLibroData.lunes,
      //   Martes: personalizarLibroData.martes,
      //   Miercoles: personalizarLibroData.miercoles,
      //   Jueves: personalizarLibroData.jueves,
      //   Viernes: personalizarLibroData.viernes,
      //   Sabado: personalizarLibroData.sabado,
      //   Domingo: personalizarLibroData.domingo
      // },
      correo_quien_paga: correoRegalo ? correoRegalo : userData.correo,
      correo_usuario: userData.correo,
      nombre_autor: userData.nombre,
      apellido_autor: userData.apellido,
      quien_escribe: correoRegalo ? "otra_persona" : "yo",
      temas_de_interes: [
        {
          tema: 0, //"Infancia",
          cantidad_preguntas: 10
        },
        {
          tema: 1, //"Adolescencia",
          cantidad_preguntas: 10
        },
        {
          tema: 2, //"Juventud",
          cantidad_preguntas: 10
        },
        {
          tema: 3, //"Adultez",
          cantidad_preguntas: 10
        },
        {
          tema: 4, //"Vejez",
          cantidad_preguntas: 10
        },
        {
          tema: 5, //"Preguntas generales",
          cantidad_preguntas: 10
        },
      ]
    //   temas_de_interes: [
    //       {
    //       tema: parseInt(temas.primerTema.value),
    //       cantidad_preguntas: temas.preguntasPrimer 
    //       },
    //         {
    //           tema: parseInt(temas.segundoTema.value),
    //           cantidad_preguntas: temas.preguntasSegundo 
    //       },
    //       {
    //         tema: parseInt(temas.tercerTema.value),
    //         cantidad_preguntas: temas.preguntasTercero 
    //     },
    //     {
    //       tema: parseInt(temas.cuartoTema.value),
    //       cantidad_preguntas: temas.preguntasCuarto 
    //     },  
    //     {
    //       tema: parseInt(temas.quintoTema.value),
    //       cantidad_preguntas: temas.preguntasQuinto
    //       },
    //         {
    //           tema: parseInt(temas.sextoTema.value),
    //           cantidad_preguntas: temas.preguntasSexto 
    //       },
    //       {
    //         tema: temas.septimoTema.value != null ? parseInt(temas.septimoTema.value) : 0,
    //         cantidad_preguntas: temas.preguntasSeptimo ? temas.preguntasSeptimo : 0
    //     },
    //     {
    //       tema: temas.octavoTema.value != null ? parseInt(temas.octavoTema.value) : 0,
    //       cantidad_preguntas: temas.preguntasOctavo ? temas.preguntasOctavo : 0
    //     },  
    //     {
    //       tema: temas.novenoTema.value != null ? parseInt(temas.novenoTema.value) : 0,
    //       cantidad_preguntas: temas.preguntasNoveno ? temas.preguntasNoveno : 0 
    //       },
    //         {
    //           tema: temas.decimoTema.value != null ?  parseInt(temas.decimoTema.value) : 0,
    //           cantidad_preguntas: temas.preguntasDecimo ? temas.preguntasDecimo : 0
    //       },
    //       {
    //         tema: temas.undecimoTema.value != null ? parseInt(temas.undecimoTema.value) : 0,
    //         cantidad_preguntas: temas.preguntasUndecimo ? temas.preguntasUndecimo : 0
    //     },
    //     {
    //       tema: temas.duodecimoTema.value != null ? parseInt(temas.duodecimoTema.value) : 0,
    //       cantidad_preguntas: temas.preguntasDuodecimo ? temas.preguntasDuodecimo : 0 
    //     },  
    // ]
      
    }
    console.log("Datita libro",data)
    fetch(url,{
      method: "POST",
      headers:{
        "Content-type": "application/json",
        // "x-access-token": Auth.getToken()
    },
    body: JSON.stringify(data)
    }).then((res)=>{
      console.log("res1",res)
    })
  }
  
  const guardar_payku = (email,order,amount,id,status) => {

    var url = process.env.REACT_APP_HOST_URL+"api/v1/payku"
    var data = {
      order: order,
      monto: amount,
      idTransaccion: id,
      email: email,
      status: status
    }
    fetch(url,{
      method: "POST",
      headers:{
        "Content-type": "application/json",
        // "x-access-token": Auth.getToken()
    },
    body: JSON.stringify(data)
    }).then((res)=>{
      console.log("res1",res)
      crear_usuario()
      })

  }




  return(
    <Fragment>
      { 
      pagina == null ? 
        <div className="bg-page h-full w-full"> 
        </div>
      
     : pagina ? 
     <div className="h-full w-full bg-page text-center place-items-center 
      space-y-8 flex flex-col py-8">
        <div className="text-titulo">¡Felicidades!</div>
        <FontAwesomeIcon 
        icon={faCheckCircle} 
        color="white" 
        size="3x" 
        className="bg-[#5BAC90] rounded-full border-4 border-[#5BAC90]"/>
        <div className="text-parrafo1">
          Hemos recibido con éxito el pago por tu suscripción.<br/> 
          Desde hoy comenzaran a llegar las preguntas de tu plan<br/><br/>
          Recibirás un email con una pequeña guía para escribir tus <br/>
          memorias, redactado por nuestras editoras, para que empieces a <br/>
          dar forma a tu libro autobiográfico a partir de nuestras preguntas.
        </div>
        <Link to={'/inicio'}>
        <img src={memoLogo} className="h-16 w-40"/>
        </Link>
      </div>
    :
     <div className="h-full w-full bg-page text-center place-items-center 
      space-y-8 flex flex-col py-8">
        <div className="text-titulo">¡Ocurrió un error!</div>
        <img src={iconoerror} height='100' width='100' className="pb-24"/>
        <div className="text-parrafo1">
          Ha surgido un error en el proceso de pago. <br/>
          Por favor intenta nuevamente.

        </div>
        <Link to={'/inicio'}>
        <img src={memoLogo} className="h-16 w-40"/>
        </Link>
      </div>


      }
    </Fragment>
  )
}

export default PagoExitoso;