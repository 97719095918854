import React, {useState} from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/imgs/logo negro.png"
import Swal from "sweetalert2";






function ValidateEmail(inputText)
{
    var mailformat = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    if(inputText?.match(mailformat))
    {
    return true;
    }
    else
    {
    return false;
    }
}



export default function ConfirmacionCompra()  {
  var correoRegalo = JSON.parse(sessionStorage.getItem('emailData'))


  const [Correoconfirmacion,setCorreoconfirmacion] = useState(correoRegalo ? correoRegalo : "")


  var planData = JSON.parse(sessionStorage.getItem('planData')).plan;
  var userData = JSON.parse(sessionStorage.getItem('userData'));
  const copias = JSON.parse(sessionStorage.getItem('resumenCompraData'))
  var personalizarLibroData = JSON.parse(sessionStorage.getItem('personalizarLibroData'))

  
  console.log("plandata",planData)
  console.log("nombre",userData.nombre)
  console.log("2",JSON.parse(sessionStorage.getItem('planData')).plan);
  console.log("4",JSON.parse(sessionStorage.getItem('personalizarLibroData')));

 



      

  console.log("plancito",planData)





  const ir_a_payku = () => {

    var CorreoCorrecto = ValidateEmail(Correoconfirmacion)

    if(CorreoCorrecto){

      var url = "https://app.payku.cl/api/transaction"

      var data = {
  
        "email": correoRegalo,
        "order": 25000,
        "subject": "Pago payku solicitud",
        "amount": 113,
        // "amount": 100,
        "urlreturn": process.env.REACT_APP_HOST_URL_FRONT+"pago_exitoso/",
        // "urlnotify": process.env.REACT_APP_HOST_URL+"api/v1/payku/pago_payku/"+props.oferta._id
       }
          console.log("DATA DATA ",data)
          fetch(url,{
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": "Bearer tkpu82ca4b8579c070d34edd9abd1561"
              },
              body: JSON.stringify(data)
          })
          .then((res)=>{
              console.log(res)
              return  res.json()
          }).then((res)=>{
            console.log("este es el segundo res",res)
          })


    }else{
      Swal.fire({
        title: "Correo incorrecto!!!",
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
        html: '<p class="text-tabla">Por favor, revise si ingresó bien su correo.</p>',
      }).then((res)=>{
        return
      })

    }    
  }



  return(
    <Fragment>
      <div className="bg-page overflow-x-hidden w-full h-full">
     <div className="flex justify-center bg-page">
            <div className="flex flex-row static w-11/12 mt-10 justify-center h-40">
                <div className="text-titulo">
                    Confirmación de compra
                </div>
                
               
          </div>
      </div>

        <div className="flex justify-center">
          <div className="border-solid border-[#D1CAB8] border-2 bg-[#FFFFFF64] w-[52%]">
            <div className="flex flex-col text-center text-letra1 font-semibold font-cormorant text-[30px]">
              <div className="">
                  Plan de {planData.cantidadPreguntas} preguntas
              </div>
              <div className="-mt-3">
                  ${planData.valorCantidadPreguntas} 
              </div>    
            </div>
            <div className="text-[#F3AC1A] text-[32px] font-work-sans font-semibold  text-center">
              +
            </div>
            <div className="flex flex-col text-center text-letra1 font-semibold font-cormorant text-[30px]">
              <div>
                {copias.copias} copias del libro
              </div>
              <div className="-mt-3">
                $ {parseInt((30000 * copias.copias)-30000)}
              </div>    
            </div>
            <div className="text-[#F3AC1A] text-[32px] font-work-sans font-semibold  text-center">
              +
            </div>
            <div className="flex flex-col text-center text-letra1 font-semibold font-cormorant text-[30px]">
              <div>
                {copias.profesional} hora de edición
              </div>
              <div className="-mt-3">
              $ {parseInt(50000 * copias.profesional)}   
              </div>    
            </div>
            <div className="flex justify-center">
              <div className="border-b-2 border-[#D1CAB8] w-4/6 mb-4"></div>    
            </div>
            <div className="text-center text-letra1 font-semibold font-cormorant text-[50px] mb-4">
              Total: ${113}
            </div>  
          </div>
        </div>
        <div className="flex justify-center mt-3">
          <div className="flex flex-col text-center w-2/5">
            <div className="font-cormorant text-[30px] text-letra1 font-medium">
                Correo electrónico de quien paga
            </div>
            <input value={Correoconfirmacion} onChange={(e) => {setCorreoconfirmacion(e.target.value)}} className="border-b-2 border-[#707070] bg-page text-center font-work-sans font-normal text-[#717171] text-[20px]">
               
            </input>
          </div>
        </div>
        <div className="flex flex-row space-x-4 justify-center mt-12 md:mb-6">
          <Link to={'/resumen_compra'}>
            <button className="py-2 px-20 estilo-botones text-botones">VOLVER</button>
          </Link>

          {/* <Link to={'/metodo_pago'}> <button 
          className="py-2 px-20 estilo-botones text-botones">PAGAR</button></Link> */}
          <button onClick={() => {ir_a_payku(Correoconfirmacion)}}
          className="py-2 px-20 estilo-botones text-botones">PAGAR</button>
        </div>
        </div>
    </Fragment>
  )
}