import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavBarAdmin from "../Components/NavbarAdmin";
import Swal from "sweetalert2";
import iconoborrar from '../assets/iconos/eliminar.png'
import iconoeditar from '../assets/iconos/editar.png'
import Select from "react-select";
import { CustomStyle, CustomTheme, estados, sexos, temas } from "../utils/OpcionesSelect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner} from '@fortawesome/free-solid-svg-icons'

export default function PreguntasAdmin(){
    const [preguntas, setPreguntas] = useState([])
    const [estado,setEstado] = useState('undefined')
    const [sexo,setSexo] = useState('undefined')
    const [tema, setTema] = useState('undefined')
    const [LoadPreguntas, setLoadPreguntas] = useState(false);

    const Toast = Swal.mixin({
        customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-label',
            closeButton: 'swal2-closebtn'
        }
    })

    const borrar_pregunta = (id) => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/preguntas/"+id

        fetch(url, {
            method: "DELETE"
        }).then((res)=>{
            if (res.status == 200){
                Toast.fire({
                    toast:true,
                    showConfirmButton: false,
                    position: "top-right",
                    timer: 2500,
                    icon: "success",
                    title: 'Pregunta eliminada'
                })
            }
            return res.json()
        }).then((res)=>{
            load_preguntas()
        })
        
    }
    
    const filtro_mundial = () => {
        setPreguntas([])
        setLoadPreguntas(true)
        if((sexo.value === null || sexo.value==='undefined')
         && (tema.value === null || tema.value ==='undefined') 
         && (estado.value === null || estado.value ==='undefined')){
            load_preguntas()
            return
        }


        var url = process.env.REACT_APP_HOST_URL+"api/v1/preguntas/filtro/params?sexo="+sexo.value+"&temas="+tema.value+"&estados="+estado.value

        fetch(url, {
            method: "GET",
        }).then((res)=>{            
            return res.json()
        }).then((res)=>{
            setTimeout(()=> {setLoadPreguntas(false);            
                setPreguntas(res)}, 100)
        }).catch((err)=>{
        })
    }
    

    const load_preguntas = () => {

        var url = process.env.REACT_APP_HOST_URL+"api/v1/preguntas/"
        fetch(url, {
            method: "GET",
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            console.log("RES RES ",res)
            setLoadPreguntas(false)
            setPreguntas(res)
        }).catch((err)=>{
        })
    }
    const mostrar_temas = (temas) => {
        let array = []
        temas.map((label)=>{
            array.push(label.label)
        })
        var temas = array.join(", ")
        return temas
    }

    useEffect(()=>{
        load_preguntas()
    }, [])

    useEffect(()=>{
        if(sexo.value != null || tema.value != null || estado.value != null){
        filtro_mundial()}
    }, [sexo,tema,estado])

    return(
        <div className=" overflow-hidden bg-page w-full h-full">
                <NavBarAdmin id={'preguntas'} />


            <div className="flex justify-center">
            <div className="flex flex-row static w-11/12 justify-between h-40">
                <div className="text-titulo">
                    Preguntas
                </div>
                <div className="grid content-center">
                    <div className="flex flex-row">
                    


                    <Select options={sexos} theme={CustomTheme} placeholder={"Sexo"}
                className="border rounded-full h-12  w-28 text-botones2 font-work-sans border-memo mr-2"
                 onChange={setSexo} styles={CustomStyle}  />



                 <Select options={temas} theme={CustomTheme} placeholder={"Categorías"}
                 className="border rounded-full h-12  w-72 text-botones2 font-work-sans border-memo mr-2"
                 onChange={setTema} styles={CustomStyle} />


                <Select options={estados} theme={CustomTheme} placeholder={"Estado"}
                className="border rounded-full h-12  text-botones2 w-36 font-work-sans border-memo mr-2"
                 onChange={setEstado} styles={CustomStyle} />

                    
                </div>
                </div>
            
            </div>


            </div>      
            <div className="flex justify-center">
                
                <div className="w-11/12 max-h-128 overflow-y-auto">

                <div className="table w-full">
                    { /*  Cabeceras de la tabla      */}
                    <div className="table-header-group">
                        <div className="table-row text-cabecera-tabla">
                            <div className="table-cell borde-tabla w-[8%]">Estado</div>
                            <div className="table-cell borde-tabla pl-4 w-[20%]">Categoria</div>
                            <div className="table-cell borde-tabla w-[60%]">Pregunta</div>
                            <div className="table-cell borde-tabla w-[12%] pl-16">Acciones</div>
                            
                        </div>
                    </div>

                            
                    { /*         FILAS        */     }
                    {
                        
                        preguntas.map((pregunta)=>{
                        return( 
                            <div className="table-row-group">
                            <div className="table-row text-tabla">
                                <div className="table-cell borde-tabla py-2 align-middle">{pregunta.estado ? "Activa" : "Borrador"}</div>
                                <div className="table-cell text-left pl-4 borde-tabla py-2 align-middle">
                                    {
                                        ((tema !== 'undefined' || tema.value != null) && tema.label != 'Todos') ?
                                        tema.label
                                        :
                                        console.log("TEMAS ",pregunta.temas)
                                    }
                                    {
                                        ((tema !== 'undefined' || tema.value != null) && tema.label != 'Todos') ? 
                                        tema.label : 
                                        <div>
                                            {pregunta.temas[0].label} 
                                            {
                                            pregunta.temas.length > 1 ? 
                                                <div data-bs-placement="right" className="tooltip">...<span className="tooltiptext">{mostrar_temas(pregunta.temas)}</span> </div>
                                                : 
                                                null
                                            }
                                        </div>
                                    }
                                </div>
                                <div className="table-cell borde-tabla align-middle">{pregunta.pregunta}</div>
                                <div className="table-cell borde-tabla align-middle">
                                    <div className="flex justify-end mr-5 place-content-center">
                                        <div className="flex flex-row">
                                            <div className="boton editar pt-2">
                                                <Link to={{pathname:"/editar_pregunta", state: {pregunta : pregunta}}}>
                                                    <button title="Editar Pregunta" className="mr-3">
                                                        <img className="w-[60px] h-[60px]" src={iconoeditar} />
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="boton borrar pt-2">
                                                <button title="Eliminar Pregunta" onClick={()=> Toast.fire({
                                                            title: 'Borrar pregunta',width: '40%',
                                                            icon: 'info',
                                                            html: '<p class="text-tabla">Estás seguro de querer borrar esta pregunta?</p>',
                                                            confirmButtonText: 'BORRAR PREGUNTA'
                                                        }).then((result)=>{
                                                            if (result.isConfirmed){
                                                                borrar_pregunta(pregunta._id)
                                                            }})}>
                                                    <img className="w-[60px] h-[60px]" src={iconoborrar} />
                                                </button>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        )
                    })
                    }                
                </div>
               
                    
                </div>
            </div>
            <div className="w-full flex">
            {
                LoadPreguntas ? 
                <div className="mx-auto mt-2 mb-8">
                <div className='text-titulo'> Cargando...</div>
                </div>
                :
                null
            }
            </div>
            <div className="flex justify-center w-full pt-10">
                    <Link to={'/nueva_pregunta'} className="pl-8">
                        <button 
                        className="estilo-botones text-botones2 px-32 py-3">
                        AÑADIR NUEVA PREGUNTA
                        </button>
                    </Link>
                </div>
        </div>
    )
}



    