import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const estadosCiviles = [
  'Soltero/a',
  'Casado/a',
  // 'En Pareja',
  'Viudo/A',
  'Divorciado/a',
];

const varios = [
  true,
  false
];

const sexos = [
  'Femenino',
  'Masculino'
]


function CrearMiPerfil(props){

  const [Nombre,setNombre] = useState("")
  const [Apellidos, setApellidos] = useState("")
  const [Sexo, setSexo] =  useState("")
  const [Edad, setEdad] = useState(0)
  // const [Ocupacion, setOcupacion] = useState("")
  const [Estudios, setEstudios] = useState(null)
  const [Estado, setEstado] = useState("")
  const [Hijos, setHijos] = useState(null)
  const [Nietos, setNietos] = useState(null)
  // const [Pais, setPais] = useState("")
  const [Ciudad, setCiudad] = useState("")
  const [Telefono, setTelefono] = useState("")
  const [Correo, setCorreo] = useState("")

  const saveData=()=>{
    if(Nombre.length>=2 && Apellidos.length>=2 && Sexo!=null && Edad >=8 && Estudios!=null && Estado!=null && Hijos!=null && Nietos!=null && Telefono != "")
    {
      if(Telefono.length>=9 && Telefono.length <=12)
      {
        var data = {
          nombre: Nombre,
          apellido: Apellidos,
          sexo: Sexo,
          edad: Edad,
          // ocupacion: Ocupacion,
          estudios: Estudios,
          estadoCivil: Estado,
          hijos: Hijos,
          nietos: Nietos,
          // paisCrecimiento: Pais,
          telefono: Telefono,
          correo: Correo,
        }
        console.log("DATA ",data)
    
        sessionStorage.setItem('userData', JSON.stringify(data));
        document.getElementById('continue').click();
      }
      else
      {
        Swal.fire({
          title: 'Debes ingresar un teléfono válido',
          icon: 'error',
          customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-label'
          },
          confirmButtonText: 'CERRAR',
        })
      }
    }
    else
    {
      Swal.fire({
        title: 'Debes ingresar todos los datos',
        icon: 'error',
        customClass:{
          popup: 'swal2-border',
          confirmButton: 'swal2-confirm',
          title: 'text-label'
        },
        confirmButtonText: 'CERRAR',
      })
    }
  }

  const buscarUsuarioPorCorreo = async() => {
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/getUserCorreo/"+Correo

    fetch(url,{
      method: "GET",
      headers:{
        "Content-type": "application/json",
        // "x-access-token": Auth.getToken()
      },
    }).then((res)=>{
      // console.log("res ",res)
      return res.json();
    }).then((res)=>{
      console.log("PERFIL ",res)
      if(res.status == 200)
      {
        Swal.fire({
          title: 'Perfil ya existente',
          icon: 'error',
          customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-label',
            closeButton: 'swal2-closebtn',
          },
          confirmButtonText: 'VOLVER',
        }).then((res)=>{
          if(res.isConfirmed){
              Swal.close()
            }})
      }
      else
      {
        saveData();
      }
    })
  }



  return( 
    <Fragment>
      <div className="w-full h-full overflow-x-hidden py-12 bg-page">
        <div className="flex justify-center">
          <div className="flex flex-col static">
            <div className="text-titulo">
              Crea tu perfil
            </div>
            <div className="text-parrafo1 text-center -mt-4 mb-6">Yo escribiré este libro</div>
          </div>
        </div>

        <div className="flex flex-col justify-center">
          
        <div className="flex justify-center">
        <div className="grid grid-cols-3 gap-x-16 gap-y-8 w-11/12">
          <div className="flex flex-col space-y-2">
            <span className="text-label">Nombre</span>
            <input type={'text'} placeholder="Ingrese su nombre" 
            className="select-memo" value={Nombre ? Nombre : ""}
            onChange={(e)=> setNombre(e.target.value)}/>

            <span className="text-label">Apellidos</span>
            <input type={'text'} placeholder="Ingrese sus apellidos"  value={Apellidos ? Apellidos : null}
            onChange={(e)=> setApellidos(e.target.value)}
            className="select-memo"/>
          {console.log("edad",Edad)}
            <span className="text-label">Sexo</span>
            <select 
            className="select-memo" value={Sexo ? Sexo : ""} onChange={(e)=>setSexo(e.target.value)}>
              <option value={null} selected>---</option>
              {sexos.map((sexo)=>{
                return(
                  <option value={sexo}>{sexo}</option>
                )
              })}
            </select>
            <span className="text-label">Edad</span>
            <input type={'number'} min={0} placeholder="Edad" value={Edad ? Edad : 0}
            onChange={(e)=> setEdad(e.target.value)}
            className="select-memo"/>
          </div>
          <div className="flex flex-col space-y-2 text-left">
            <span className="text-label">¿Estudiaste en la universidad?</span>
            <select 
            className="select-memo" value={Estudios ? Estudios :""} onChange={(e)=>setEstudios(e.target.value)}>
              <option value={null} selected>---</option>
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </select>
            {/* <span className="text-label">Ocupación</span>
            <input type={'text'} placeholder="Ocupación"  value={Ocupacion ? Ocupacion : null}
            onChange={(e)=> setOcupacion(e.target.value)}
            className="select-memo"/> */}
            <span className="text-label">Estado civil</span>
            <select 
            className="select-memo" value={Estado ? Estado :""} onChange={(e)=>setEstado(e.target.value)}>
              <option value={null} selected>---</option>
              {estadosCiviles.map((estadoCivil)=>{
                return(<option value={estadoCivil}>{estadoCivil}</option>);
              })}
            </select>

            <span className="text-label">¿Tienes hijos?</span>
            <select 
            className="select-memo" value={Hijos ? Hijos :""} onChange={(e)=>setHijos(e.target.value)}>
              <option value={null} selected>---</option>
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </select>

            <span className="text-label">¿Tienes nietos?</span>
            <select 
            className="select-memo" value={Nietos ? Nietos :""} onChange={(e)=>setNietos(e.target.value)}>
              <option value={null} selected>---</option>
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </select>
            {/* <label className="text-label">¿Cuántos hijos tienes?</label>
            <input type={'number'} min={0} placeholder="Número de Hijos"  value={Hijos ? Hijos : null}
            onChange={(e)=> setHijos(e.target.value)}
            className="select-memo"/> */}

            {/* <label className="text-label">País donde creció</label>
            <input type={'text'} placeholder="País"  value={Pais ? Pais : null}
            onChange={(e)=> setPais(e.target.value)}
            className="select-memo"/> */}
          </div>
          <div className="flex flex-col space-y-2 text-left">
            <label className="text-label">Teléfono de contacto</label>
            <input type={'text'} placeholder="Teléfono (Ej: +56999999999)" value={Telefono ? Telefono : null}
            onChange={(e)=> setTelefono(e.target.value)}
            className="select-memo"/>

            <label className="text-label">Correo electrónico</label>
            <input type={'text'} placeholder="Correo Electrónico" value={Correo ? Correo : null}
            onChange={(e)=> setCorreo(e.target.value)}
            className="select-memo"/>
          </div>
      </div>    
    </div>
        <div className="w-11/12">
          <div className="flex justify-center">
            <div className="flex flex-row pt-[77px] pb-[157px] space-x-[41px]">
              <Link to={{ pathname: "/planes", state: { regalo: false}}}>
                <button className="text-botones estilo-botones w-[452px] h-[67px]">
                  VOLVER</button>
              </Link>
                <button onClick={()=>{buscarUsuarioPorCorreo();}}
                  className="text-botones estilo-botones w-[452px] h-[67px]">CONTINUAR</button>
                {/* <button 
                className="text-botones estilo-botones w-[452px] h-[67px]" onClick={saveData}>
                  CONTINUAR</button> */}
              {/* <Link id='continue' to={{ pathname: "/temas_interes", state: { regalo: false}}} /> */}
              <Link id='continue' to={{ pathname: "/resumen_compra", state: { regalo: false}}} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default CrearMiPerfil;