const plan1 = {
    id: '1',
    cantidadPreguntas: 48,
    paginas: 60,
    fotos: 0,
    valorCantidadPreguntas: 15000,
    valorEnvioPreguntas: 5000,
    tipoTapa: 'BLANDA',
    valorTapa:2000,
    precioPlan: 160000,
    tipoPapel:'HOJA DE BIBLIA',
    valorPapel:1000
  }

  const plan2 = {
    id: '2',
    cantidadPreguntas: 72,
    paginas: 88,
    fotos: 6,
    valorCantidadPreguntas: 15000,
    valorEnvioPreguntas: 5000,
    tipoTapa: 'BLANDA',
    valorTapa:2000,
    precioPlan: 212000,
    tipoPapel:'HOJA DE BIBLIA',
    valorPapel:1000
  }
  
  const plan3 = {
    id: '3',
    cantidadPreguntas: 96,
    paginas: 120,
    fotos: 10,
    valorCantidadPreguntas: 15000,
    valorEnvioPreguntas: 5000,
    tipoTapa: 'BLANDA',
    valorTapa:2000,
    precioPlan: 290000,
    tipoPapel:'HOJA DE BIBLIA',
    valorPapel:1000
  }

  const plan60 = {
    id: '1',
    cantidadPreguntas: 60,
    paginas: 60,
    fotos: 0,
    valorCantidadPreguntas: 15000,
    valorEnvioPreguntas: 5000,
    tipoTapa: 'BLANDA',
    valorTapa:2000,
    precioPlan: 160000,
    tipoPapel:'HOJA DE BIBLIA',
    valorPapel:1000
  }


export {
    plan1,
    plan2,
    plan3,
    plan60
}