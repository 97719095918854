import React, {useState, useEffect} from "react";
import { Fragment } from "react/cjs/react.production.min";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const userLoged = JSON.parse(localStorage.getItem("memoria_creativa_user"))

const estadosCiviles = [
    'Soltero/a',
    'Casado/a',
    // 'Union Civil',
    'Viudo/a',
    'Divorciado/a',
  ];
  
  const sexos = [
    'Femenino',
    'Masculino',
    // 'No Binario'
  ]

export default function EditarPerfilUser (){
  const [Alert, setAlert] = useState(null)
  const [Nombre,setNombre] = useState("")
  const [Apellidos, setApellidos] = useState("")
  const [Sexo, setSexo] =  useState("")
  const [Edad, setEdad] = useState(0)
  const [Ocupacion, setOcupacion] = useState("")
  const [Estado, setEstado] = useState("")
  const [Hijos, setHijos] = useState(0)
  const [Pais, setPais] = useState("")
  const [Ciudad, setCiudad] = useState("")
  const [Telefono, setTelefono] = useState("")
  const [Correo, setCorreo] = useState("")

  useEffect(()=>{
    getUser()
}, [])
  const getUser = async () => {
    const id = userLoged._id
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/"+id
    console.log("url", url)
    await fetch(url,{
    method: "GET",
    headers: {
    }
    }).then((res)=>{
    console.log("res", res)
    if(res.status == 401){

    }
    return res.json()
    }).then((res)=>{
    console.log("res",res)
    if(res.solicitud)
    {
        setCiudad(res.solicitud.ciudad)
        setTelefono(res.solicitud.telefono)
        setCorreo(res.solicitud.correo)
        setPais(res.solicitud.paisCrecimiento)
        setHijos(res.solicitud.hijos)
        setEstado(res.solicitud.estadoCivil)
        setOcupacion(res.solicitud.ocupacion)
        setEdad(res.solicitud.edad)
        setNombre(res.solicitud.nombre)
        setApellidos(res.solicitud.apellido)
        setSexo(res.solicitud.sexo)
        setCiudad(res.solicitud.ciudadCrecimiento)
    }
    })
}

  const guardar_usuario = () => {
    const id = userLoged._id
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/"+id
    var data = {
        nombre: Nombre,
        apellido: Apellidos,
        sexo: Sexo,
        edad: Edad,
        cupacion: Ocupacion,
        estadoCivil: Estado,
        hijos: Hijos,
        paisCrecimiento: Pais,
        ciudadCrecimiento: Ciudad,
        telefono: Telefono,
        correo: Correo
    }

    fetch(url,{
      method:"PUT",
      headers:{
          "Content-Type": "application/json"
      },
      body: JSON.stringify(data)

  }).then(res => {
    console.log("res",res)
    return res.json()
}).then(res => {
    Swal.fire({
        title: 'Perfil Editado Correctamente',
        icon: 'success',
        confirmButtonText: 'Volver al perfil',
        confirmButtonColor: '#F27474',
        }).onConfirm(              setTimeout(() => {
          window.location.href = "/mi_perfil"
        }, 1000))
    
})
  }



  return( 
    <Fragment>

      


      <div className="h-full w-full py-12 bg-page">
      <div className="flex justify-center">
            <div className="flex flex-col static">
                <div className="text-titulo">
                      Editar perfil
                </div>
                {/* <div className="text-parrafo1 text-center -mt-4 mb-6">Yo escribiré este libro</div> */}
              </div>
          </div>

        <div className="flex justify-center">
        <div className="grid grid-cols-3 gap-x-16 gap-y-8 w-11/12">
       
          <div className="flex flex-col space-y-2">
            {/* <span className="text-label">Nombre</span>
            <input type={'text'} placeholder="Ingrese su nombre" 
            className="select-memo" value={Nombre ? Nombre : ""}
            onChange={(e)=> setNombre(e.target.value)}/>

            <span className="text-label">Apellidos</span>
            <input type={'text'} placeholder="Ingrese sus apellidos"  value={Apellidos ? Apellidos : null}
            onChange={(e)=> setApellidos(e.target.value)}
            className="select-memo"/> */}

            {/* <span className="text-label">Sexo</span>
            <select 
            className="select-memo" value={Sexo ? Sexo : null} onChange={(e)=>setSexo(e.target.value)}>
              <option value={""}>---</option>
              {sexos.map((sexo)=>{
                return(
                   <option value={sexo}>{sexo}</option>
                )

              })}
            </select> */}
            {/* <span className="text-label">Edad</span>
            <input type={'text'} placeholder="Edad" value={Edad ? Edad : null}
            onChange={(e)=> setEdad(e.target.value)}
            className="select-memo"/> */}
          </div>
          <div className="flex flex-col space-y-2 text-left">
            <span className="text-label">Nombre</span>
            <input type={'text'} placeholder="Ingrese su nombre" 
            className="select-memo" value={Nombre ? Nombre : ""}
            onChange={(e)=> setNombre(e.target.value)}/>

            <span className="text-label">Apellidos</span>
            <input type={'text'} placeholder="Ingrese sus apellidos"  value={Apellidos ? Apellidos : null}
            onChange={(e)=> setApellidos(e.target.value)}
            className="select-memo"/>
            <span className="text-label">Edad</span>
            <input type={'text'} placeholder="Edad" value={Edad ? Edad : null}
            onChange={(e)=> setEdad(e.target.value)}
            className="select-memo"/>
            <label className="text-label">Teléfono de contacto</label>
            <input type={'text'} placeholder="Teléfono" value={Telefono ? Telefono : null}
            onChange={(e)=> setTelefono(e.target.value)}
            className="select-memo"/>
            {/* <span className="text-label">Ocupación</span>
            <input type={'text'} placeholder="Ocupación"  value={Ocupacion ? Ocupacion : null}
            onChange={(e)=> setOcupacion(e.target.value)}
            className="select-memo"/> */}
            {/* <span className="text-label">Estado Civil</span>
            <select 
            className="select-memo" value={Estado ? Estado : ""} onChange={(e)=>setEstado(e.target.value)}>
              <option value="">---</option>
              {estadosCiviles.map((estadoCivil)=>{
                return(<option value={estadoCivil}>{estadoCivil}</option>);
              })}
            </select> */}

            {/* <label className="text-label">¿Cuántos hijos tienes?</label>
            <input type={'number'} placeholder="Número de Hijos"  value={Hijos ? Hijos : 0}
            onChange={(e)=> setHijos(e.target.value)}
            className="select-memo"/>

            <label className="text-label">País donde creció</label>
            <input type={'text'} placeholder="País"  value={Pais ? Pais : null}
            onChange={(e)=> setPais(e.target.value)}
            className="select-memo"/> */}

          </div>


          <div className="flex flex-col space-y-2 text-left">
            {/* <label className="text-label">Ciudad donde creció</label>
            <input type={'text'} placeholder="Ciudad"  value={Ciudad ? Ciudad  : null}
            onChange={(e)=> setCiudad(e.target.value)}
            className="select-memo"/> */}

            {/* <label className="text-label">Teléfono de Contacto</label>
            <input type={'text'} placeholder="Teléfono" value={Telefono ? Telefono : null}
            onChange={(e)=> setTelefono(e.target.value)}
            className="select-memo"/> */}

            {/* <label className="text-label">Correo Electrónico</label>
            <input type={'text'} placeholder="Correo Electrónico" value={Correo ? Correo : null}
            onChange={(e)=> setCorreo(e.target.value)}
            className="select-memo"/> */}
          </div>

         

      </div>
      
      </div> <div className="w-full">
            <div className="flex justify-center">
        <div className="flex flex-row pt-[77px] pb-[157px] space-x-[41px]">
          <Link to={'/mi_perfil'}>
            <button className="text-botones estilo-botones w-[452px] h-[67px]">
              VOLVER</button>
          </Link>
         
            <button 
            className="text-botones estilo-botones w-[452px] h-[67px]" onClick={guardar_usuario}>
              GUARDAR</button>

        </div></div></div>
    </div>
    </Fragment>
  );
}