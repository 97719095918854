import React, {useState,useEffect} from "react";
import NavBarAdmin from "../Components/NavbarAdmin";
import iconoperfil from '../assets/iconos/profile.png'
import iconopeso from '../assets/iconos/compra.png'
import iconofolder from '../assets/iconos/folder.png'
import { Link } from "react-router-dom";

export default function LibrosAdmin(){
    const [historias,setHistorias] = useState([]);
    const [filter, setFilter] = useState("");
    const [filterEstado, setFilterEstado] = useState(null);
    const [loading, setLoading] = useState(false);

    const load_libros = () => {

        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/"
        fetch(url, {
            method: "GET",
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            setHistorias(res.reverse())
            console.log(res)
        }).catch((err)=>{
            console.log("err",err)
        })
    }

    const filtro_mundial = () => {
        setHistorias([])
        setLoading(true)

        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/filtro/params?numero="+filter+"&estado="+filterEstado

        fetch(url,{
            method: "GET",
        }).then((res)=>{
            return res.json();
        }).then((res)=>{
            console.log("RES RES ",res);
            setHistorias(res.reverse());
        })
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    useEffect(()=>{
        load_libros()
        console.log("Libros",historias)
        // setLoading(false)
    }, [])

    useEffect(()=>{
        console.log("EJECUTADO")
        filtro_mundial()
    },[filterEstado])


        return(
        <div className=" overflow-hidden bg-page w-full h-full">
                <NavBarAdmin id={'libros'} />


            <div className="flex justify-center">
            <div className="flex flex-row static w-11/12 justify-between h-40">
                <div className="text-titulo">
                    Libros
                </div>
                <div className="grid content-center">
                    <div className="flex flex-row">
                    <input placeholder="Buscar por N° pedido" onChange={(e)=> setFilter(e.target.value)} value={filter} className="mr-2 pl-3 font-work-sans bg-white text-botones2 w-full h-12 border-memo"></input>
                    <select onChange={(e)=> setFilterEstado(e.target.value)} className="border rounded-full h-12 w-2/3 text-botones2 font-work-sans border-memo">
                        <option value={null}>Estado</option>
                        <option value={true}>En Desarrollo</option>
                        <option value={false}>Finalizado</option>
                    </select>
                </div>
                </div>
            
            </div>


            </div>
            <div className="flex justify-center">
                
                <div className="w-11/12 max-h-128 overflow-y-auto">

                <div className="table w-full">
                    { /*  Cabeceras de la tabla      */}
                    <div className="table-header-group">
                        <div className="table-row text-cabecera-tabla">
                            <div className="table-cell borde-tabla">Pedido</div>
                            <div className="table-cell borde-tabla">Estado</div>
                            <div className="table-cell borde-tabla">Nombre</div>
                            <div className="table-cell borde-tabla">Preguntas T/E/R</div>
                            <div className="table-cell borde-tabla">Fotos T/A</div>
                            <div className="table-cell borde-tabla">Acciones</div>
                        </div>
                    </div>

                            
                    { /*         FILAS        */     }
                   
                    {
                    historias.map((libro) => {
                        let enviadas = 0;
                        let respondidas = 0;
                        for(let pregunta of libro.preguntas)
                        {
                            if(pregunta.fecha_envio != null)
                            {
                                enviadas++;
                            }

                            if(pregunta.Estado != 0)
                            {
                                if(pregunta.respuesta != "")
                                {
                                    respondidas++;
                                }
                            }
                        }
                        return(
                        <div className="table-row-group">
                            <div className="table-row text-tabla">
                            { 
                                libro.numero.includes(filter) ? 
                                <>
                                    <div className="table-cell borde-tabla align-middle py-2">{libro.numero ? libro.numero : "----"}</div>
                                    <div className="table-cell borde-tabla  align-middle">{libro.Estado ? "En Desarrollo" : "Finalizado"}</div>
                                    <div className="table-cell borde-tabla align-middle">{libro.nombre_autor} {libro.apellido_autor}</div>
                                    <div className="table-cell borde-tabla align-middle">{libro.plan.numero_preguntas} / {enviadas} / {respondidas}</div>
                                    <div className="table-cell borde-tabla align-middle">10 / {libro.foto_portada ? 1:0}</div><div className="table-cell borde-tabla align-middle">
                                        <div className="flex flex-row mt-3">
                                            <div className="icono PERFIL">
                                                <Link to={{ pathname: "/perfil_usuario_admin", state: { correo: libro.correo_usuario, pedido: libro.numero } }}>
                                                    <button title="Ver Perfil" className="mr-3">
                                                        <img className="w-[60px] h-[60px]" src={iconoperfil} />
                                                    </button>
                                                </Link>
                                            </div>
                                            {/* <div className="ICONO SIGNOPESO">
                                                <Link to={{ pathname: "/comprobante_de_compra", state: { pedido: libro.numero } }}>
                                                    <button className="mr-3">
                                                        <img className="w-[60px] h-[60px]" src={iconopeso} />
                                                    </button>
                                                </Link>
                                            </div> */}
                                            <div className="ICONO CARPETA">
                                                <Link to={{ pathname: "/preguntas_y_respuestas", state: libro }}>
                                                    <button title="Ver Preguntas y Respuestas" className="mr-3">
                                                        <img className="w-[60px] h-[60px]" src={iconofolder} />
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </> 
                                : null 
                            }
                        </div> 
                    
                    </div>)
                    })
                    }
                </div>
                <div className="w-full flex">
                    {
                        loading == true ? 
                        <div className="mx-auto mt-2 mb-8">
                        <div className='text-titulo'> Cargando...</div>
                        </div>
                        :
                        null
                    }
                </div>
                </div>
            </div>
        </div>
    )
}