import React,{Fragment, useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import Logo from "../assets/imgs/logo negro.png"

export default function ConfirmacionCorreo(props){

  const [correo, setCorreo] = useState("");
  const [codigo, setCodigo] = useState("")

  const verificar_email = (correo,code) =>{

    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/verificarEmail"
    
    var data = {
      correo: correo,
      code: code
    }

    fetch(url,{
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(data)
      }).then((res)=>{
        console.log("KAKAKA",res)
      })
  }


  useEffect(()=>{
    const params = new URLSearchParams(props.location.search); 
    const corre0 = params.get('correo');
    const code = params.get('code')


    setCorreo(corre0)
    setCodigo(code)


    verificar_email(corre0,code)
  },[])

  return (
    <Fragment>
      <div className="flex flex-col place-items-center bg-page text-[#504E4E] w-full h-full">
        <div className="pt-[2%]">
          <img className="h-[220px] w-[240px]" src={Logo}/>
        </div>
        <div className="text-center font-cormorant font-medium text-[64px] text-[#504E4E] pt-[2%] pb-[2%]">
          Confirmación de Correo
        </div>
        <div className='text-label -mt-6 mb-24'>Su correo ha sido verificado correctamente.</div>
        <div className='flex w-1/3 justify-center'>
          <Link to={'/cambiar_clave2?correo='+correo+'&code='+codigo}>
            <button 
            className="estilo-botones text-botones px-24 w-full h-[67px]">
              CREAR CLAVE
            </button>
          </Link>
        </div>
        
        
      </div>
    </Fragment>
  );
}