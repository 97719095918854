import React, {useState} from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/imgs/logo negro.png";
import FloatingLabelInput from 'react-floating-label-input';
import Auth from '../middleware/Auth';
import Swal from "sweetalert2";

function CambiarClave(){
  const [esAdmin, setEsAdmin] = useState(false)
  const [correo, setCorreo] = useState("");

  const cargar_correo = () => {

    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/getUserCorreo/"+correo

    fetch(url,{
      method:"GET",
  }).then((res)=>{
    console.log("res", res)
    if(res.status == 401){

    }
    return res.json()
    }).then((res)=>{
    console.log("res2",res)
    if(res.solicitud)
    {
      enviar_correo()
    }else{Swal.fire({
      title: 'Ups!',
      icon: 'error',
      width: '35%',
      html: '<p class="text-tabla">No pudimos encontrar el correo ingresado</p>',
      showCloseButton: true,
      customClass:{
        popup: 'swal2-border',
        confirmButton: 'swal2-confirm',
        title: 'swal2-title',
        closeButton: 'swal2-closebtn',
      },
      confirmButtonText: 'VOLVER'

    })

    }
    })
  }

  const enviar_correo = () => {

    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/"+correo+"/solicitar_cambio_clave"

    fetch (url, {
      method: "GET",
    }).then((res) => {
      console.log("res",res)
        if(res.status == 401){
          console.log("algo malo pasó aqui")
        }
        if(res.status == 200){
          Swal.fire({
            title: 'Solicitud enviada',
            icon: 'success',
            width: '35%',
            html:'<p class="text-tabla">Revise su correo para solicitar su clave</p>',
            showCloseButton: true,
            customClass:{
              popup: 'swal2-border',
              confirmButton: 'swal2-confirm',
              title: 'swal2-title',
              closeButton: 'swal2-closebtn',
            },
            confirmButtonText: 'ENTENDIDO'
      
          })
        }
        return res.json()
      }).then((res)=>{
        
      })
  }

    return(
    <Fragment>
      {alert}
      <div 
      className="space-y-8 flex flex-col font-serif place-items-center bg-[#F9F8F4] h-full w-full">
        <div 
        className="pt-36">
          <img className="h-40 w-40" src={Logo}/>
        </div>
        <div 
        className="text-center text-titulo">
          Escribe tu Historia
        </div>
        {/* <div class="grid grid-cols-2 gap-2"> */}
        <div className="font-cormorant w-[338px] font-semibold text-[26px] text-letra1">Usuario (Correo Electrónico)</div>
            <input type={'text'} value={correo} onChange={e=>setCorreo(e.target.value)}
            className="text-inputs w-1/4 bg-page select-memo "></input>
          
        {/* </div> */}
        <div className="font-sans mt-6">
          <button 
          className="mr-10 w-60 h-12 text-botones2 estilo-botones" onClick={cargar_correo}>
              ENVIAR CORREO 
          </button>
          <Link to={'/login'}>
            <button 
            className="w-60 py-2 estilo-botones text-botones2">
              VOLVER
            </button>
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default CambiarClave;