import React from "react";
import NavBarAdmin from "../Components/NavbarAdmin";


export default function Comprobante(props) {

    return(
        <div className="bg-page h-full w-full">
            <NavBarAdmin />

            <div className="flex justify-center">
                <div className="flex flex-row static w-11/12 mt-10 justify-start">
                    <div className="text-titulo">
                       Comprobante de Compra
                   </div>                   
                </div>
            </div>

            <div className="bg-page flex justify-center">
                <div className="w-11/12">
                    <div className="font-work-sans font-normal text-[30px] text-[#504E4E] mb-4">
                    PEDIDO {props.location.state.pedido}            
                    </div>

                <div className="font-cormorant font-semibold text-letra1 text-[50px] mt-12">
                    Plan 50 preguntas
                </div>
                </div>
                <div className="flex flex-col">

                </div>
            </div>
            <div className="flex justify-center">    
            <div className="w-11/12">

            <div className="grid grid-cols-3">
                    <div className="columna1">
                        <div className="flex flex-row">
                            <div className="text-label">
                            Cantidad de preguntas:
                            </div>
                            <div className="pl-2 text-botones text-[28px]">Xxxxx</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="text-label">
                            Envío de preguntas:
                            </div>
                            <div className="pl-2 text-botones text-[28px]">Xxxxx</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="text-label">
                            Tipo de tapa:
                            </div>
                            <div className="pl-2 text-botones text-[28px]">Xxxxx</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="text-label">
                            Tipo de papel:
                            </div>
                            <div className="pl-2 text-botones text-[28px]">Xxxxx</div>
                        </div>

                    </div>
                    <div className="columna2">
                        <div className="flex flex-row">
                            <div className="text-label">
                            Tipo de tapa:
                            </div>
                            <div className="pl-2 text-botones text-[28px]">Xxxxx</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="text-label">
                            Copias del libro:
                            </div>
                            <div className="pl-2 text-botones text-[28px]">Xxxxx</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="text-label">
                            Asistencia de edición:
                            </div>
                            <div className="pl-2 text-botones text-[28px]">Xxxxx</div>
                        </div>
                        <div className="flex flex-row">
                            <div className="text-label">
                            Fotografías:
                            </div>
                            <div className="pl-2 text-botones text-[28px]">Xxxxx</div>
                        </div>

                    </div>

                    <div className="columna3">
                        <div className="space-y-3">
                        <button className="w-2/3 h-10 text-botones estilo-botones">
                            AMPLIAR EL PLAN
                        </button>
                        <button className="w-2/3 h-10 text-botones estilo-botones">
                            DESCARGAR COMPROBANTE
                        </button>
                        <button className="w-2/3 h-10 text-botones estilo-botones">
                            DESCARGAR BOLETA
                        </button>
                        </div>
                    </div>


                </div>

                </div></div>
        </div>

    )
}