/* eslint-disable jsx-a11y/alt-text */
import React, {useState} from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/imgs/logo negro.png"
import Swal from "sweetalert2";
import Auth from '../middleware/Auth';

const userLoged = JSON.parse(localStorage.getItem("memoria_creativa_user"))

function LoginAdmin(){
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [Alert, setAlert] = useState(null);



  const iniciarSesion = async() => 
  {
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/loginAdmin"

    var data = {
      correo: correo,
      password: password
    }
    console.log(data)
    fetch(url,{
      method: "POST",
      headers: {"Content-type": "application/json"},
      body: JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(response=>{
      console.log(response)
      if(response.user!=null)
      {
        if(response.user.emailVerified === true)
        {
          setAlert(
            Swal.fire({
              title: 'Bienvenido',
              icon: 'success',
              heightAuto: true,
              width: '35%',
              customClass:{
                popup: 'swal2-border',
                confirmButton: 'swal2-confirm',
                title: 'swal2-title',
              },
              confirmButtonText: 'ENTRAR'
            })
            .onConfirm(
              localStorage.setItem("memoria_creativa_user", JSON.stringify(response.user)),
              Auth.authenticateToken(response.token),
              setTimeout(() => {
                window.location.href = "/libros_admin"
              }, 1000)
            )
          )
        }
        else {
            Swal.fire({
              title: 'Falta activar cuenta',
              icon: 'error',
              width: '35%',
              showCloseButton: true,
              customClass:{
                popup: 'swal2-border',
                confirmButton: 'swal2-confirm',
                title: 'swal2-title',
                closeButton: 'swal2-closebtn'
              },
              confirmButtonText: 'CERRAR'
        
            })
            .onConfirm(setTimeout(() => {
          
              window.location.reload()},1000))
          
        }
      }
      else
      {
        
        Swal.fire({
          title: 'Credenciales inválidas',
          icon: 'error',
          width: '35%',
          showCloseButton: true,
          customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'swal2-title',
            closeButton: 'swal2-closebtn'
          },
          confirmButtonText: 'CERRAR'
        }).then((result)=>{
          if (result.isConfirmed){
            Swal.close()
          }
        })
      }
    }).catch((err)=>{
        console.log("aqui")
    })
  }


  return(
    <Fragment>
      {Alert}
      <div 
      className="flex flex-col font-serif place-items-center bg-page h-full w-full">
        <div 
        className="pt-32">
          <img className="h-40 w-40" src={Logo}/>
        </div>
        <div 
        className="text-center text-titulo">
          Escribe tu historia
        </div>
        <div className="text-center text-label -mt-8">
            Administrador
        </div>
        <div className="mt-10 grid grid-cols-2 gap-x-10">
          <div className="columna1">
            <div className="font-cormorant w-[338px] font-semibold text-[26px] text-letra1">Usuario (Correo electrónico)</div>
            <input type={'text'} value={correo} onChange={e=>setCorreo(e.target.value)}
            className="text-inputs w-full bg-page select-memo "></input>
          </div>
          <div className="columna2">
          <div className="font-cormorant font-semibold w-[338px] text-[26px] text-letra1">Contraseña</div>
            <input type={'password'} value={password} onChange={e=>setPassword(e.target.value)}
            className="text-inputs w-full bg-page select-memo "></input>
          </div>
        </div>
        <div className="mt-16 w-11/12">
          <div className="flex justify-center">
            <button  onClick={()=>iniciarSesion()}
            className="mr-10 w-1/6 h-[67px] py-2 px-6 estilo-botones text-botones">
              ACCEDER
            </button>
            <Link to={'/inicio'}>
              <button 
              className="w-[314px] h-[67px] estilo-botones text-botones">
                VOLVER
              </button>
            </Link>
          </div>
          
        </div>
        <div className="flex justify-center">        
        <div className="mt-8 mr-6"><Link className="text-botones2 tracking-wide w-44" 
        to={'/cambiar_clave'}>OLVIDÉ MIS ACCESOS</Link></div>
      </div></div>
    </Fragment>
  );
}

export default LoginAdmin;