import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ModalCorreoRegalo from "../Modals/ModalCorreoRegalo";

const estadosCiviles = [
  'Soltero/a',
  'Casado/a',
  // 'En pareja',
  'Viudo/a',  
  'Divorciado/a',
];

const sexos = [
  'Femenino',
  'Masculino'
]
const parentescos = [
  'Hermano/a',
  'Madre/Padre',
  'Hijo/a',
  'Primo/a',
  'Tío/a',
  'Amigo/a',
  'Sobrino/a',
  'Abuelo/a',
  'Nieto/a'
]


//Arreglar separacion entre span e inputs

function CrearSuPerfil(props){


  const [ modalState, setModalState] = useState(false);
  const [ modalState1, setModalState1] = useState(false);

  const [Nombre,setNombre] = useState("")
  const [Apellidos, setApellidos] = useState("")
  const [Sexo, setSexo] =  useState("")
  const [Edad, setEdad] = useState(0)
  const [Ocupacion, setOcupacion] = useState("")
  const [Estudios, setEstudios] = useState(null)
  const [Estado, setEstado] = useState("")
  const [Hijos, setHijos] = useState(null)
  const [Nietos, setNietos] = useState(null)
  // const [Pais, setPais] = useState("")
  // const [Ciudad, setCiudad] = useState("")
  const [Telefono, setTelefono] = useState("")
  const [Correo, setCorreo] = useState("");
  // const [Parentesco, setParentesco] = useState('');
  // var disabled = (Nombre.length>=2 && Apellidos.length>=2 && Sexo!=null && Edad >=8 && Estudios!=null && Estado!=null && Hijos!=null && Nietos!=null && Telefono.length<6 && Correo != "")

  const validar_campos = () => {
    if(Nombre.length>=2 && Apellidos.length>=2 && Sexo!=null && Edad >=8 && Estudios!=null && Estado!=null && Hijos!=null && Nietos!=null && Telefono.length != "" && Correo != "")
    {
      if(Telefono.length >= 9 && Telefono.length <= 12)
      {
        buscarUsuarioPorCorreo();
      }
      else
      {
        Swal.fire({
          title: 'Debes ingresar un teléfono válido',
          icon: 'error',
          customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-label'
          },
          confirmButtonText: 'CERRAR',
        })
      }
    }
    else
    {
      Swal.fire({
        title: 'Debes ingresar todos los datos',
        icon: 'error',
        customClass:{
          popup: 'swal2-border',
          confirmButton: 'swal2-confirm',
          title: 'text-label'
        },
        confirmButtonText: 'CERRAR',
      })
    }
  }

  const saveData=()=>{
    // console.log("disabled ",disabled)
    var data = {
      nombre: Nombre,
      apellido: Apellidos,
      sexo: Sexo,
      edad: Edad,
      // ocupacion: Ocupacion,
      estudios: Estudios,
      estadoCivil: Estado,
      hijos: Hijos,
      nietos: Nietos,
      // paisCrecimiento: Pais,
      telefono: Telefono,
      correo: Correo,
      // parentesco: Parentesco,
    }

    sessionStorage.setItem('userData', JSON.stringify(data));
    var obj = JSON.parse(sessionStorage.getItem('userData'));
  }

  const buscarUsuarioPorCorreo = async() => {
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/getUserCorreo/"+Correo

    fetch(url,{
      method: "GET",
      headers:{
        "Content-type": "application/json",
        // "x-access-token": Auth.getToken()
      },
    }).then((res)=>{
      // console.log("res ",res)
      return res.json();
    }).then((res)=>{
      if(res.status == 200)
      {
        Swal.fire({
          title: 'Perfil ya existente',
          icon: 'error',
          customClass:{
            popup: 'swal2-border',
            confirmButton: 'swal2-confirm',
            title: 'text-label',
            closeButton: 'swal2-closebtn',
          },
          confirmButtonText: 'VOLVER',
        }).then((res)=>{
          if(res.isConfirmed){
              Swal.close()
            }})
      }
      else
      {
        setModalState(false);
        saveData();
        setModalState(true);
      }
    })
  }

  return( 
    <Fragment>
      <div className="overflow-x-hidden h-full w-full py-12 bg-page">
        <div className="flex justify-center">
          <div className="flex flex-col static">
            <div className="text-titulo">
              Crea su perfil
            </div>
            <div className="text-parrafo1 text-center -mt-4 mb-6">Este libro será un regalo.</div>
          </div>
        </div>

        <div className="flex justify-center">
        <div className="grid grid-cols-3 gap-x-16 gap-y-8 w-11/12">
          <div className="flex flex-col space-y-2">
            <span className="text-label">Nombre</span>
            <input type={'text'} placeholder="Ingrese su nombre" 
            className="select-memo" value={Nombre ? Nombre : ""}
            onChange={(e)=> setNombre(e.target.value)}/>

            <span className="text-label">Apellidos</span>
            <input type={'text'} placeholder="Ingrese sus apellidos"  value={Apellidos ? Apellidos : ""}
            onChange={(e)=> setApellidos(e.target.value)}
            className="select-memo"/>
          {console.log("edad",Edad)}
            <span className="text-label">Sexo</span>
            <select 
            className="select-memo" value={Sexo ? Sexo : ""} onChange={(e)=>setSexo(e.target.value)}>
              <option value={null}>---</option>
              {sexos.map((sexo)=>{
                return(
                  <option value={sexo}>{sexo}</option>
                )
              })}
            </select>

            <span className="text-label">Edad</span>
            <input type={'number'} placeholder="Edad" value={Edad ? Edad : 0}
            onChange={(e)=> setEdad(e.target.value)} min={0}
            className="select-memo"/>
            {/* <div className="flex flex-col"> */}
              {/* <span className="text-label">Parentesco</span>
              <select type={'text'} placeholder="Edad" 
              className="select-memo"
              value={Parentesco} onChange={(e)=>setParentesco(e.target.value)}>
                <option value={null}>---</option>
                {parentescos.map((parentesco)=>{
                  return(<option value={parentesco}>{parentesco}</option>)
                })}
              </select> */}
            {/* </div> */}
          </div>
          <div className="flex flex-col space-y-2 text-left">
            
            <span className="text-label">¿Estudió en la universidad?</span>
            <select 
            className="select-memo" value={Estudios ? Estudios :""} onChange={(e)=>setEstudios(e.target.value)}>
              <option value={null} selected>---</option>
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </select>
            {/* <span className="text-label">Ocupación</span>
            <input type={'text'} placeholder="Ocupación"  value={Ocupacion ? Ocupacion : ""}
            onChange={(e)=> setOcupacion(e.target.value)}
            className="select-memo"/> */}
            <span className="text-label">Estado civil</span>
            <select 
            className="select-memo" value={Estado ? Estado : ""} onChange={(e)=>setEstado(e.target.value)}>
              <option value={null}>---</option>
              {estadosCiviles.map((estadoCivil)=>{
                return(<option value={estadoCivil}>{estadoCivil}</option>);
              })}
            </select>

            <span className="text-label">¿Tiene hijos?</span>
            <select 
            className="select-memo" value={Hijos ? Hijos :""} onChange={(e)=>setHijos(e.target.value)}>
              <option value={null} selected>---</option>
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </select>

            <span className="text-label">¿Tiene nietos?</span>
            <select 
            className="select-memo" value={Nietos ? Nietos :""} onChange={(e)=>setNietos(e.target.value)}>
              <option value={null} selected>---</option>
              <option value={true}>Sí</option>
              <option value={false}>No</option>
            </select>
            {/* <label className="text-label">¿Cuántos hijos tienes?</label>
            <input type={'number'} placeholder="Número de Hijos"  value={Hijos ? Hijos : 0}
            onChange={(e)=> setHijos(e.target.value)} min={0}
            className="select-memo"/> */}
          </div>
          <div className="flex flex-col space-y-2 text-left">
            {/* <label className="text-label">País donde creció</label>
            <input type={'text'} placeholder="País"  value={Pais ? Pais : ""}
            onChange={(e)=> setPais(e.target.value)}
            className="select-memo"/> */}
            <label className="text-label">Teléfono de contacto</label>
            <input type={'text'} placeholder="Teléfono" value={Telefono ? Telefono : ""}
            onChange={(e)=> setTelefono(e.target.value)}
            className="select-memo"/>

            <label className="text-label">Correo electrónico</label>
            <input type={'text'} placeholder="Correo Electrónico" value={Correo ? Correo : ""}
            onChange={(e)=> setCorreo(e.target.value)}
            className="select-memo"/>
          </div>
          
      </div>    
    </div>
        <div className="flex flex-row space-x-[41px] justify-center pt-[77px] pb-[157px]">
          <Link to={{ pathname: "/planes", state: { regalo: true}}}>
            <button 
            className="text-botones estilo-botones w-[452px] h-[67px]">VOLVER</button>
          </Link>
            <button onClick={()=>{validar_campos();}}
            className="text-botones estilo-botones w-[452px] h-[67px]">CONTINUAR</button>
          {/* <Link id='continue' to={{ pathname: "/resumen_compra", state: { regalo: true}}}>
            <button disabled={!disabled} onClick={()=>{setModalState(true);saveData();}}
            className="text-botones estilo-botones w-[452px] h-[67px]">CONTINUAR</button>
          </Link> */}
        </div>
      </div>
      {modalState && <ModalCorreoRegalo setModalState={setModalState}/>}
    </Fragment>
  )
};

export default CrearSuPerfil;