import React from 'react'

class Auth extends React.Component{

	static authenticateToken(x_access_token){
		localStorage.setItem('x_access_token', x_access_token);
		console.log("sesion actual: ", localStorage.getItem('x_access_token'));
	}

	static updateToken(x_access_token){
    // console.log("updateToken: ", x_access_token);
		if (x_access_token !== "" && x_access_token !== null) {
			localStorage.setItem('x_access_token', x_access_token);
			// console.log("token actualizado: ", localStorage.getItem('x_access_token'));
		}
		else {
			// console.log("es vacio, se mantiene: ", localStorage.getItem('x_access_token'));
		}

	}

	static isUserAuthenticate(){
		if(localStorage.getItem('x_access_token') === null)	return false
		return true
	}

	static deauthenticateUser(){
		localStorage.removeItem('x_access_token');
	}

	static getToken(){
    //console.log("getToken", localStorage.getItem('x_access_token'));
		return localStorage.getItem('x_access_token');
	}
}

export default Auth;
