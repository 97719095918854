import React, {useState,useEffect} from "react";
import NavBarAdmin from "../Components/NavbarAdmin";

export default function PerfilUsuarioAdmin(props){

    const [correo1, setCorreo1] = useState(props.location.state ? props.location.state.correo : null)
    const [User, setUser] = useState([])
    const [Libro, setLibro] = useState(null)
    const [fotos, setFotos] = useState([])

    const cargar_usuario = () => {

    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/getUserCorreo/"+correo1

    fetch(url,{
      method:"GET",
  }).then((res)=>{
    console.log("res", res)
    return res.json()
    }).then((res)=>{
    console.log("res2",res)
    setUser(res.solicitud)
    obtenerLibro();
    })
  }

    const obtenerLibro = async() => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/obtenerHistoriaCorreo"

        var data = {
            correo: correo1
        }

        await fetch(url,{
            method: "POST",
            headers: {
                "Content-type": "application/json",
            },
            body: JSON.stringify(data)
        }).then((res)=>{
            return res.json();
        }).then(async (res)=>{
            console.log("res.json ",res.historia)
            setLibro(res.historia)
            cargarFotos(res.historia._id)
        })
    }

    const cargarFotos = async(id) => {
        var url = process.env.REACT_APP_HOST_URL+"api/v1/historias/"+id+"/imagenes"

        await fetch(url,{
            method: "GET",
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            console.log("RESPUESTA GET ",res)
            setFotos(res)
        })
    }

  useEffect(()=>{
    cargar_usuario()
},[])
    
    return(
    /*             NAVBAR + TITULO            */

        <div className="bg-page overflow-x-hidden w-full h-full">
            <NavBarAdmin id={'libros'}/>
            {console.log("USUARIOxd",User)}
            <div className="flex justify-center">
                 <div className="flex flex-row static w-11/12 mt-10 justify-start">
                     <div className="text-titulo">
                           Información
                    </div>
                </div>
            </div>
       { /*             CUERPO                */}

            <div className="bg-page flex justify-center pb-10">
                <div className="w-11/12">
                    <div className="font-work-sans font-normal text-[30px] text-[#504E4E] mb-4">
                            PEDIDO {props.location.state.pedido}
                    </div>
                <div className="grid grid-cols-2">
                    <div className="primera-columna">
                        <div className="text-navbar">Es para:</div>
                        <div className="flex flex-row">
                            {
                                Libro?.quien_escribe == "yo" ?
                                    <>
                                        <button className="text-botones text-[24px] h-[40px] w-[136px] rounded-full border-[#D05A3D] border mr-5">Usuario</button>
                                        <button className="text-botones text-[24px] h-[40px] w-[136px] rounded-full border-black border mb-8">Regalo</button>
                                    </>
                                    :
                                    Libro?.quien_escribe == "otra_persona" ?
                                    <>
                                        <button className="text-botones text-[24px] h-[40px] w-[136px] rounded-full border-black border mr-5">Usuario</button>
                                        <button className="text-botones text-[24px] h-[40px] w-[136px] rounded-full border-[#D05A3D] border mb-8">Regalo</button>
                                    </>
                                    :
                                    <>
                                        <button className="text-botones text-[24px] h-[40px] w-[136px] rounded-full border-black border mr-5">Usuario</button>
                                        <button className="text-botones text-[24px] h-[40px] w-[136px] rounded-full border-black border mb-8">Regalo</button>
                                    </>
                            }
                        </div>

                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Nombre:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.nombre}</div>
                        </div>
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Apellidos:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.apellido}</div>
                        </div>
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Sexo:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.sexo}</div>
                        </div>
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Edad:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.edad}</div>
                        </div>
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Estudios universitarios:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.estudios ? 'Sí':'No'}</div>
                        </div>
                        {/* <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Ocupación:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.ocupacion}</div>
                        </div> */}
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Estado civil:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.estadoCivil}</div>
                        </div>
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Hijos:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.hijos ? 'Sí' : 'No'}</div>
                        </div>

                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Nietos:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.nietos ? 'Sí' : 'No'}</div>
                        </div>
                        
                        {/* <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">País:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.paisCrecimiento}</div>
                        </div>
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Ciudad:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.ciudadCrecimiento}</div>
                        </div>

                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Frecuencia</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">Orlando</div>
                        </div>
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Día de envío:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">Orlando</div>
                        </div>
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Horario de Envío:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">Orlando</div>
                        </div> */}
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Teléfono:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.telefono}</div>
                        </div>
                        
                        {/* <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Email:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">??</div>
                        </div> */}
                        
                        <div className="flex flex-row">
                            <div className="text-parrafo1 mr-2">Email suscriptor:</div>
                            <div className="font-work-sans font-normal text-[32px] text-[#504E4E] pt-2">{User.correo}</div>
                        </div>
                        
                    </div>
                    <div className="segundacolumna">
                        <div className="flex text-navbar justify-center">Fotos del libro</div>
                        <div className="flex flex-col items-center ">
                            {
                                fotos.map((foto)=>{
                                    console.log("FOTO ",foto)
                                    return(
                                        <a href={foto.url} target="_blank">
                                            <img src={foto.url} className="w-14 h-14 m-1"></img>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                </div>

            </div>
            {/* <div className="bg-page">
                <div className="flex justify-center mr-32 py-8">
                    <button className="py-2 px-20 estilo-botones text-botones">EDITAR MI PERFIL</button>
                </div>
            </div> */}
        </div>
    )
}