import React,{Fragment, useEffect} from 'react'
import { Link } from "react-router-dom";
import Logo from "../assets/imgs/logo negro.png"
import NavBarPrincipal from '../Components/NavbarPrincipal';

function TerminosCondiciones(){
  const userLoged = JSON.parse(localStorage.getItem("memoria_creativa_user")) != null ?  JSON.parse(localStorage.getItem("memoria_creativa_user")):null
  useEffect(()=>{
    // localStorage.clear()
  },[])

  return (
    <Fragment>
      <div className="overflow-x-hidden bg-page w-full h-full">
        <NavBarPrincipal id={4}/>
      </div>
    </Fragment>
  );
}

export default TerminosCondiciones;