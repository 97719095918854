import React, {useEffect,useState} from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Drawer from '../Components/Navbar';

const user = JSON.parse(localStorage.getItem("memoria_creativa_user"))

function MiPerfil(){

    const [usuario, setUsuario] = useState([]);

    useEffect(()=>{
        getUser()
    }, [])
    
    const getUser = async () => {
        const id = user._id
        var url = process.env.REACT_APP_HOST_URL+"api/v1/users/"+id
        console.log("url", url)
        await fetch(url,{
        method: "GET",
        headers: {
        }
        }).then((res)=>{
        console.log("res", res)
        if(res.status == 401){

        }
        return res.json()
        }).then((res)=>{
        console.log("res",res)
        if(res.solicitud)
        {
            setUsuario(res.solicitud)
            console.log(usuario)
        }
        })
    }

    return(
    <Fragment>
        <div className="bg-page h-full w-full">
            <Drawer id={2}/>
            {/* <div className="pt-20 pl-24 text-[#504E4E]">
                    <h1 className="text-3xl">Mi Perfil</h1>
            </div> */}
 <div className="flex justify-center">
            <div className="flex flex-row static w-11/12 justify-between h-40">
                <div className="text-titulo">
                    Mi perfil
                </div>
                </div></div>



             <div className="flex justify-center">
                
                <div className="w-11/12 max-h-128 overflow-y-auto">


            <div className="grid grid-cols-2 gap-2">
                <div className="columna1">
                <div className="flex flex-row">
                    <div className="text-label">Nombre:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.nombre}</div>
                </div>
                <div className="flex flex-row">
                    <div className="text-label">Apellidos:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.apellido}</div>
                </div>
                <div className="flex flex-row">
                    <div className="text-label">Sexo:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.sexo}</div>
                </div>  
                <div className="flex flex-row">
                    <div className="text-label">Edad:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.edad}</div>
                </div>
                <div className="flex flex-row">
                    <div className="text-label">Estudios universitarios:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.estudios ? 'Sí':'No'}</div>
                </div>    
                {/* <div className="flex flex-row">
                    <div className="text-label">Ocupación:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.ocupacion}</div>
                </div>   */}
                <div className="flex flex-row">
                    <div className="text-label">Estado civil:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.estadoCivil}</div>
                </div>  
                </div>
                <div className="columna2">
                <div className="flex flex-row">
                    <div className="text-label">Hijos:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.hijos ? 'Sí':'No'}</div>
                </div>
                <div className="flex flex-row">
                    <div className="text-label">Nietos:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.nietos ? 'Sí':'No'}</div>
                </div>
                {/* <div className="flex flex-row">
                    <div className="text-label">País:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.paisCrecimiento}</div>
                </div>
                <div className="flex flex-row">
                    <div className="text-label">Ciudad:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.ciudadCrecimiento}</div>
                </div>   */}
                <div className="flex flex-row">
                    <div className="text-label">Teléfono:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.telefono}</div>
                </div>  
                <div className="flex flex-row">
                    <div className="text-label">Email:</div>
                    <div className="pl-2 mt-1 text-cabecera-tabla">{usuario.correo}</div>
                </div>  
                </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
                <div></div>
                <div className="pt-20 pl-24">
                    <Link to={'/editar_perfil'}>
                        <button 
                        className="text-botones estilo-botones h-12 px-28">
                        EDITAR PERFIL
                        </button>
                    </Link>
                </div>
            </div>
        </div>
        </div></div>
    </Fragment>
    );
}

export default MiPerfil;