    /*         ESTILOS             */
const CustomStyle = {
    control: (base) => ({
        ...base,
        backgroundColor: 'bg-page',
        border: 'none'
    }),
    indicatorSeparator: () => ({
    }),
    option: (base) => ({
        ...base,
        color: '#504E4E',
        height: '22px',
        padding: '0 0 0 16px',
    }),
    menuList: (base) => ({
        ...base,
        backgroundColor: '#F7EEEE'
    })
    }

function CustomTheme(theme){
        return {
            ...theme,
            borderRadius:0,
            colors: {
                ...theme.colors,
                primary: 'none',
                primary50: 'none',
                primary75: 'none'
            }
        }
    }   

/*                       OPCIONESS             */

const sexos = [
    { value: 'undefined', label: 'Todos'},
    { value: 'Masculino', label: 'Masculino'},
    { value: 'Femenino', label: 'Femenino'},
]

const edades = [
    { value: '1-20', label: '1-20'},
    { value: '21-40', label: '21-40'},
    { value: '41-60', label: '41-60'},
    { value: '61-80', label: '61-80'},
    { value: 'Más de 80', label: 'Más de 80'},
]

const estados = [
    { value: 'undefined', label: 'Todos'},
    { value: 'Soltero/a', label: 'Soltero/a'},
    { value: 'Casado/a', label: 'Casado/a'},
    { value: 'Viudo/a', label: 'Viudo/a'},
    // { value: 'En pareja', label: 'En pareja'},
    { value: 'Divorciado/a', label: 'Divorciado/a'}
]

const temas = [
    {value: 'undefined', label:'Todos'},
    {value: 0, label:'Infancia'},
    {value: 1, label:'Adolescencia'},
    {value: 2, label:'Juventud'},
    {value: 3, label:'Adultez'},
    {value: 4, label:'Vejez'},
    {value: 5, label:'Preguntas generales'}
]
// const temas = [
//     { value: 'undefined', label: 'Todos'},
//     { value: 0, label: 'Infancia, adolescencia y juventud' },
//     { value: 1, label: 'Mis abuelos' },
//     { value: 2, label: 'Mis padres' },
//     { value: 3, label: 'Estudios' },
//     { value: 4, label: 'Trabajo y crecimiento laboral' },
//     { value: 5, label: 'Amistad' },
//     { value: 6, label: 'Matrimonio / Relación de pareja' },
//     { value: 7, label: 'Maternidad / Paternidad' },
//     { value: 8, label: 'Vida familiar / Tradiciones' },
//     { value: 9, label: 'Cosas cotidianas' },
//     { value: 10, label: 'Anédcotas' },
//     { value: 11, label: 'Viajes y vacaciones' },
//     { value: 12, label: 'Mascotas' },
//     { value: 13, label: 'Cumpleaños y celebraciones' },
//     { value: 14, label: 'Relación con los hijos' },
//     { value: 15, label: 'Nietos y bisnietos' },
//     { value: 16, label: 'Moda / Belleza / Autocuidado' },
//     { value: 17, label: 'Autos / Tecnología' },
//     { value: 18, label: 'Deportes y pasatiempos' },
//     { value: 19, label: 'Pandemia' },
//     { value: 20, label: 'Comidas / platos / recetas' },
//     { value: 21, label: 'Sueños y proyectos' },
//     { value: 22, label: 'El mundo de antes' },
//     { value: 23, label: 'Autoconocimiento' },
//     { value: 24, label: 'Mis gustos' },
//     { value: 25, label: 'Forma de ser' },
//     { value: 26, label: 'Reflexiones' },
//     { value: 27, label: 'Espiritualidad' },
//     { value: 28, label: 'Mi manera de ver el mundo' },
//     { value: 29, label: 'Vejez' }
// ]
const numero_preguntas = [
    { value:1,  label: '1 pregunta'},
    { value:2,  label: '2 preguntas'},
    { value:3,  label: '3 preguntas'},
    { value:4,  label: '4 preguntas'},
    { value:5,  label: '5 preguntas'},
    { value:6,  label: '6 preguntas'},
    { value:7,  label: '7 preguntas'},
    { value:8,  label: '8 preguntas'},
    { value:9,  label: '9 preguntas'},
    { value:10, label: '10 preguntas'},
    { value:11, label: '11 preguntas'},
    { value:12, label: '12 preguntas'},
    { value:13, label: '13 preguntas'},
    { value:14, label: '14 preguntas'},
    { value:15, label: '15 preguntas'},

]



/*         OPCIONES PARA
           NUEVA PREGUNTA                 */

const temasNP = [
    {value: 0, label:'Infancia'},
    {value: 1, label:'Adolescencia'},
    {value: 2, label:'Juventud'},
    {value: 3, label:'Adultez'},
    {value: 4, label:'Vejez'},
    {value: 5, label:'Preguntas generales'}
    // { value: 0, label: 'Infancia / adolescencia / Juventud' },
    // { value: 1, label: 'Mis abuelos' },
    // { value: 2, label: 'Mis padres' },
    // { value: 3, label: 'Estudios' },
    // { value: 4, label: 'Trabajo y crecimiento laboral' },
    // { value: 5, label: 'Amistad'},
    // { value: 6, label: 'Matrimonio/Relación de pareja' },
    // { value: 7, label: 'Maternidad/Paternidad' },
    // { value: 8, label: 'Vida Familiar / Tradiciones' },
    // { value: 9, label: 'Cosas cotidianas' },
    // { value: 10, label: 'Anédcotas' },
    // { value: 11, label: 'Viajes y vacaciones' },
    // { value: 12, label: 'Mascotas' },
    // { value: 13, label: 'Cumpleaños y celebraciones' },
    // { value: 14, label: 'Relación con los hijos' },
    // { value: 15, label: 'Nietos y bisnietos' },
    // { value: 16, label: 'Moda/belleza/autocuidado' },
    // { value: 17, label: 'Autos/tecnología' },
    // { value: 18, label: 'Deportes y pasatiempos' },
    // { value: 19, label: 'Pandemia' },
    // { value: 20, label: 'Comidas/platos/recetas' },
    // { value: 21, label: 'Sueños y proyectos' },
    // { value: 22, label: 'El mundo de antes' },
    // { value: 23, label: 'Autoconocimiento' },
    // { value: 24, label: 'Mis gustos' },
    // { value: 25, label: 'Forma de ser' },
    // { value: 26, label: 'Reflexiones' },
    // { value: 27, label: 'Espiritualidad' },
    // { value: 28, label: 'Mi manera de ver el mundo' },
    // { value: 29, label: 'Vejez' }
]

const estadosNP = [
    // { value: 'Todos', label: 'Todos'},
    { value: 'Soltero/a', label: 'Soltero/a'},
    { value: 'Casado/a', label: 'Casado/a'},
    { value: 'Viudo/a', label: 'Viudo/a'},
    // { value: 'En pareja', label: 'En pareja'},
    { value: 'Divorciado/a', label: 'Divorciado/a'}
]
const sexosNP = [
    // { value: 'todos', label: 'Todos'},
    { value: 'Masculino', label: 'Masculino'},
    { value: 'Femenino', label: 'Femenino'},
]
const opcionCorta = [
    // { value: 'todos', label: 'Todos'},
    { value: 'Sí', label: 'Sí'},
    { value: 'No', label: 'No'},
]

export {
    CustomStyle, CustomTheme,
    sexos, edades, estados, temas,
    sexosNP, estadosNP, temasNP,
    numero_preguntas, opcionCorta
}

