import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import libro2 from '../assets/imgs/libro2.png'
import Plan1 from "../Components/Plan1";
import Plan2 from "../Components/Plan2";
import Plan60 from "../Components/Plan60";
import {plan1, plan2,plan3,plan60} from '../utils/DetallePlanes'
import Swal from "sweetalert2";

export default function ResumenDeCompra(props){
  console.log("akakaa",props)
  const [soloLectura, setSoloLectura] = useState(true)
  const [transaccionID, setTransaccionID] = useState("")
  const [numero, setNumero] = useState("")
  const [regalo, setRegalo] = useState(false)

  var planData = JSON.parse(sessionStorage.getItem('planData')).plan;
  var userData = JSON.parse(sessionStorage.getItem('userData'));
  const copias = JSON.parse(sessionStorage.getItem('resumenCompraData'))
  //var personalizarLibroData = JSON.parse(sessionStorage.getItem('personalizarLibroData'))

  var temas = JSON.parse(sessionStorage.getItem('temasInteresData'));
  console.log("plandata",planData)
  console.log("nombre",userData)
  console.log("temitas???",temas)
  console.log("xD?",JSON.parse(sessionStorage.getItem('temasInteresData')))
  console.log("2",JSON.parse(sessionStorage.getItem('planData')).plan);
  //console.log("4",JSON.parse(sessionStorage.getItem('personalizarLibroData')));




useEffect(()=> {
  const params = new URLSearchParams(props.location.search);
  const regalo = params.get('regalo');
  console.log("REGALO ",regalo)
  setRegalo(regalo)
  sessionStorage.setItem('paykuID',JSON.stringify(transaccionID))
  console.log("?¿",sessionStorage.getItem('paykuID'))
},[transaccionID])


  const obtener_numero_orden = () => {

    var url = process.env.REACT_APP_HOST_URL+"api/v1/payku/numero_orden"
    console.log("url",url)
    fetch(url, {
      method: "GET",
  }).then((res)=>{
      return res.json()
  }).then((res)=>{
      setNumero(res)
      console.log("xd",numero)
      ir_a_payku(res)
  }).catch((err)=>{
      console.log("err",err)
  })
  }



  // const valorCopia = 30000;
  // const valorProfesional = 50000;

  // const [Copias,setCopias] = useState(1);
  // const [Profesional,setProfesional] = useState(0);


  const ir_a_payku = (numero1) => {
 
      console.log("numero1",numero1)

      var url = "https://app.payku.cl/api/transaction"

      var data = {
  
        "email": userData.correo,
        "order": numero1,
        "subject": "Pago Payku Memocreativa",
        //"amount": planData.precioPlan,
         "amount": 50,
        "urlreturn": process.env.REACT_APP_HOST_URL_FRONT+"pago_exitoso/",
        // "urlnotify": process.env.REACT_APP_HOST_URL+"api/v1/payku/pago_payku/"+props.oferta._id
       }
          console.log("DATA DATA ",data)
          fetch(url,{
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                  "Authorization": "Bearer tkpu82ca4b8579c070d34edd9abd1561"
              },
              body: JSON.stringify(data)
          })
          .then((res)=>{
              console.log(res)
              return  res.json()
          }).then((res)=>{
            console.log("este es el segundo res",res)
            setTransaccionID(res.id)
            if(res.status == "register"){
              var url = res.url
              var url_sanitized = url.replace(/\\/g, '')
              window.location.href = url_sanitized}
        })


    
  }
  return(
      <Fragment>
        <div className="h-full w-full overflow-x-hidden bg-page">
          <div 
          className="flex flex-col text-center text-titulo place-items-center pt-8 pb-2">
            Resumen de compra
          </div>     
          
          <div className="flex justify-center w-11/12">
            <div className="w-11/12">  
              <div className="grid grid-cols-3 mt-16">
                <div className="Columna1 w-[70%] ml-12">
                  <img src={libro2} />
                </div>
                <div className="columna2 w-[70%]">
                  <Plan60 soloLectura={soloLectura} plan60={plan60}/>
                </div>
                <div className="Columna3 text-respuesta -ml-12 w-[130%]">
                  CUANDO REVISES EL DISEÑO FINAL, DECIDES SI QUIERES COMPRAR MÁS COPIAS EXTRA.
                  {/* EN CUALQUIER MOMENTO DEL PROCESO, PODRÁS ELEGIR LA OPCIÓN DE UNA O MÁS SESIONES CON UNA EDITORA DE
                  MEMORIA CREATICA PARA EDITAR TU LIBRO Y PODRÁS AÑADIR FOTOGRAFÍAS. <br/> <br/>
                  CUANDO REVISES EL DISEÑO FINAL, DECIDES SI QUIERES IMPRIMIR MÁS COPIAS. <br/> <br/>
                  1 SESIÓN DE EDICIÓN DE 1 HORA: $ 50.000 <br/> 
                  CADA FOTOGRAFÍA EXTRA: $ 8.000 <br/>
                  1 COPIA EXTRA DE 60 PÁGINAS: $ 44.000 <br/>
                  1 COPIA EXTRA DE 88 PÁGINAS: $ 62.000 <br/>
                  1 COPIA EXTRA DE 120 PÁGINAS: $ 80.000 <br/> */}
                </div>
            </div>
          
            <div className="flex flex-row space-x-6 justify-center md:mb-6">
            {(props.location.state != undefined && props.location.state.regalo) || regalo ? 
              <Link to={'/crear_su_perfil'}>
                <button className="estilo-botones py-2 px-24 text-botones">VOLVER</button>
              </Link> : 
              <Link to={'/crear_mi_perfil'}>
                <button className="estilo-botones py-2 px-24 text-botones">VOLVER</button>
              </Link>
            }
        <button onClick={() =>{obtener_numero_orden()}}
        className="estilo-botones py-2 px-20 text-botones">IR A PAGAR</button>
        </div>
        </div>
        </div>




          {/* <div className="flex flex-row w-full justify-center space-x-[77px]">
            <div className="border-solid border-[#F3AC1A] bg-white border-2 flex 
            flex-col font-cormorant text-center w-[398px] h-[616px]">
              <div className="text-4xl text-[#504E4E] h-[157px]">
                {plan.cantidadPreguntas} preguntas
                <div className="mt-[24.5px] mb-[27.5px] border-b-2 border-[#F3AC1A] ml-[155px] w-[88px]"/>
              </div>
              <div className="text-[#717171] h-[407px] font-work-sans text-[19px]">
                Cantidad de Preguntas
                <br/>
                ${plan.valorCantidadPreguntas}
                <br/><br/>
                ENVÍO DE {plan.cantidadPreguntas} PREGUNTAS
                <br/>
                ${plan.valorEnvioPreguntas}
                <br/><br/>
                TIPO DE TAPA: {plan.tipoTapa}
                <br/>
                ${plan.valorTapa}
                <br/><br/>
                TIPO DE PAPEL: {plan.tipoPapel}
                <br/>
                ${plan.valorPapel}
                <br/><br/>
                ¿Puedo comprar más copias?
                <div className="text-sm pb-2">Si, todas las que quieras</div>
              </div>
              <div 
              className="bg-[#F3AC1A] text-white h-[50px] text-xl font-work-sans text-[20px] pt-3">
                Pagar ${plan.valorCantidadPreguntas
                +plan.valorEnvioPreguntas
                +plan.valorTapa
                +plan.valorPapel}
              </div>
            </div>

            <div className="flex justify-center text-[#F3AC1A] text-[32px] ml-5 items-center font-work-sans font-semibold  text-center">
              +
            </div>
            
            <div className="border-solid border-[#F3AC1A] bg-white border-2 flex 
            flex-col font-cormorant text-center w-[398px] h-[616px]">
              <div className="text-4xl text-[#504E4E] h-[157px]">
                Copias
                <div className="mt-[24.5px] mb-[27.5px] border-b-2 border-[#F3AC1A] ml-[155px] w-[88px]"/>
              </div>
              <div className="text-[#717171] h-[407px] font-work-sans text-[19px]">
                El plan incluye una copia impresa, pero puedes solicitar
                todas las que necesites, con un costo de $20.000 cada copia adicional
                <div className="flex flex-row justify-between px-12 pt-[100px]">
                <button 
                onClick={()=>{setCopias(Copias-1)}} disabled={Copias<2}
                className="text-[#F3AC1A] text-[32px] font-work-sans font-semibold">-</button>
                <div className="text-[#F3AC1A] text-[32px] font-work-sans font-semibold">{Copias}</div>
                <button
                onClick={()=>{setCopias(Copias+1)}}
                className="text-[#F3AC1A] text-[32px] font-work-sans font-semibold">+</button>
              </div>
              </div>
              <div 
              className="bg-[#F3AC1A] text-white h-[50px] text-xl font-work-sans text-[20px] pt-3">
                Pagar ${(Copias*valorCopia)-valorCopia}
              </div>
            </div>
          <div className="flex justify-center text-[#F3AC1A] text-[32px] ml-5 items-center font-work-sans font-semibold  text-center">
            +
          </div>

          <div className="border-solid border-[#F3AC1A] bg-white border-2 flex 
          flex-col font-cormorant text-center w-[398px] h-[616px]">
            <div className="text-4xl text-[#504E4E] h-[157px]">
              Edición
              <div className="mt-[24.5px] mb-[27.5px] border-b-2 border-[#F3AC1A] ml-[155px] w-[88px]"/>
            </div>
            <div className="text-[#717171] h-[407px] font-work-sans text-[19px]">
              Un profesional de nuestro equipo pude revisar y asesorarte en la escritura del libro. el valor por hora es de $50.000
              <div className="flex flex-row justify-between px-12 pt-[100px]">
                <button
                onClick={()=>{setProfesional(Profesional-1)}} disabled={Profesional<1}
                className="text-[#F3AC1A] text-[32px] font-work-sans font-semibold"> -</button>
                <div className="text-[#F3AC1A] text-[32px] font-work-sans font-semibold">{Profesional}</div>
                <button 
                onClick={()=>{setProfesional(Profesional+1)}}
                className="text-[#F3AC1A] text-[32px] font-work-sans font-semibold">+</button>
              </div>
            </div>
            <div className="bg-[#F3AC1A] text-white h-[50px] text-xl font-work-sans text-[20px] pt-3">
              Pagar ${Profesional*valorProfesional}
            </div>
          </div>
        </div>

            <div className="flex flex-row space-x-6 justify-center mt-20 md:mb-6">
        <Link to={'/personalizar_libro'}>
          <button className="estilo-botones py-2 px-24 text-botones">VOLVER</button>
        </Link>
        <Link to={'/confirmacion_de_compra'}>
        <button onClick={()=>{saveData()}}
        className="estilo-botones py-2 px-20 text-botones">CONTINUAR</button>
        </Link>
      </div> */}
  
    </div>
      </Fragment>
    );
};
