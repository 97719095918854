import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
// import { response } from "express";

function ModalCorreoRegalo(props){

  const [Email,setEmail] = useState('');
  const [flag, setFlag] = useState(false);
  const saveEmail = () => {
    var email = Email;

    if(email.length > 8){ 
    sessionStorage.setItem('emailData', JSON.stringify(email))
    var emailData = sessionStorage.getItem('emailData')
    console.log("Email data?",emailData)
    setFlag(true);
    // window.location.href = '/temas_interes?regalo=true'
    window.location.href = '/resumen_compra?regalo=true'
    }
    else{
    Swal.fire({
      title: 'Por favor, escribe tu correo',
      icon: 'error',
      customClass:{
        popup: 'swal2-border',
        confirmButton: 'swal2-confirm',
        title: 'text-label',
        closeButton: 'swal2-closebtn',
      },
      confirmButtonText: 'VOLVER',
    }).then((res)=>{
      if(res.isConfirmed){
          Swal.close()
        }})
  
  }
}


// useEffect(()=>{
//   const params = new URLSearchParams(props.location.search); 
//   const regalo = params.get('regalo');


//   setCodigo(code)
// },[])


  return(
    <Fragment>
      <div className="overflow-x-hidden bg-black bg-opacity-50 absolute inset-0 flex justify-center h-[1280px]"> {/* This just had items-center as a tailwindcss class */}
        <div 
        className="bg-white text-[#504E4E] border-[#D1CAB8] h-[612px] w-[52%]
        font-cormorant text-center border-2 my-[122px]">
          <div className="text-right text-xs"><button onClick={()=>{props.setModalState(false)}}>
            <FontAwesomeIcon icon={faTimesCircle} color="#D1CAB8" size="3x" className="mt-2 mr-2 hover:text-[#504E4E]"/>
          </button></div>
          <div className="text-[80px] pb-[22px]">
            Regalo
          </div>
          <div className="md:break-words text-[40px]">
            Por favor, indica el email en el que <br /> deseas recibir el comprobante de pago <br/> de tu compra.
          </div>
          <div className="flex flex-col items-center">
            <span className="text-[30px] pt-[40px]">Correo electrónico de quien paga</span>
            <input 
            value={Email} onChange={(e)=>{setEmail(e.target.value)}}
            placeholder="correo@electronico.com"
            className="border-0 border-b-2 border-[#504E4E] 
            text-[#504E4E] w-[503px] h-[40px] text-[20px] bg-white text-center"/>
          </div>
          <button onClick={saveEmail} className="estilo-botones text-botones w-1/4 h-[58px] mt-[26px] ">CONTINUAR</button>

            {/* {
              flag ? 
              <Link id='continue' to={{ pathname: "/resumen_compra", state: { regalo: true}}}>
                <button onClick={saveEmail} className="estilo-botones text-botones w-1/4 h-[58px] mt-[26px] ">CONTINUAR</button>
              </Link>
              :
              null
            } */}
        </div>
      </div>
    </Fragment>
  ); 
};

export default ModalCorreoRegalo;