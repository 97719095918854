import React, {useState, useEffect} from "react";
import {Fragment} from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/imgs/logo negro.png";
import FloatingLabelInput from 'react-floating-label-input';
import Auth from '../middleware/Auth';
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from "sweetalert2";

function CambiarClave2(props){

  const [pw1, setPassword1] = useState("");
  const [pw2, setPassword2] = useState("");
  const [confirm, setConfirmPass] = useState("");

  const [correo, setCorreo] = useState("");
  const [codigo, setCodigo] = useState("")


  useEffect(()=>{
    const params = new URLSearchParams(props.location.search); 
    const corre0 = params.get('correo');
    const code = params.get('code')

    setCorreo(corre0)
    setCodigo(code)
  },[])





  const compararPass = () => {
      console.log("dDDDDDDDDD",codigo)
    if(pw1 === pw2 && (pw1!="" && pw2!=""))
    {
        console.log("son iguales!")
      cambiarPass()
    }
    else
    {
        Swal.fire({
            title: 'Contraseñas no coinciden',
            icon: 'error',
            customClass:{
              popup: 'swal2-border',
              confirmButton: 'swal2-confirm',
              title: 'text-label'
            },
            confirmButtonText: 'CERRAR',
          })
    }
  }

  const cambiarPass = () =>  {
    var url = process.env.REACT_APP_HOST_URL+"api/v1/users/createPassword";
    var data = {
        correo : correo,
        password: pw1,
        code: codigo
    }
    console.log("aqui1")
    fetch(url,{
        method:"POST",
        headers:{
            "Content-type": "application/json",
            // "x-access-token": Auth.getToken()
    },
    body: JSON.stringify(data)
    }).then((res)=>{
    if(res.status == 401){
    //   setAlert(
    //     <SweetAlert title="Error"
    //     error
    //     onConfirm={()=>{window.location.reload()}}
    //     >
    //     </SweetAlert>
    //   )
    console.log("aqui222")
    }
        console.log("res", res)
        return res.json()
    }).then((res)=>
    {
      console.log("res", res)
      Swal.fire({
        title: 'Contraseña',
        icon: 'success',
        customClass:{
          popup: 'swal2-border',
          confirmButton: 'swal2-confirm',
          title: 'text-label'
        },
        confirmButtonText: 'IR AL INICIO',
        confirmButtonColor: '#A5DC86',
      }).then((result)=>{
        if (result.isConfirmed){
          window.location.href = '/login'
        }
      })
    })
}

    return(
    <Fragment>
      {alert}
      <div 
      className="space-y-8 flex flex-col font-serif place-items-center bg-[#F9F8F4] h-full w-full">
        <div 
        className="pt-36">
          <img className="h-40 w-40" src={Logo}/>
        </div>
        <div 
        className="text-center text-titulo">
          Escribe tu Historia
        </div>
        {/* <div class="grid grid-cols-2 gap-2"> */}
        <div className="font-cormorant w-[338px] font-semibold text-[26px] text-letra1">Contraseña</div>
            <input type={'password'} value={pw1} onChange={e=>setPassword1(e.target.value)}
            className="text-inputs w-1/5 bg-page select-memo "></input>
             <div className="font-cormorant w-[338px] font-semibold text-[26px] text-letra1">Repita Contraseña</div>
            <input type={'password'} value={pw2} onChange={e=>setPassword2(e.target.value)}
            className="text-inputs w-1/5 bg-page select-memo "></input>
          
        {/* </div> */}
        <div className="font-sans mt-6">
          <button 
          className="mr-10 w-60 h-12 text-botones2 estilo-botones" onClick={compararPass}>
              GUARDAR CONTRASEÑA
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default CambiarClave2;