import React, { Fragment, useState } from "react";
import libro2 from '../assets/imgs/libro2.png'
import Plan60 from "../Components/Plan60";
import {plan60} from '../utils/DetallePlanes'

function Planes(props){
  const [soloLectura, setSoloLectura] = useState(true)  //

  const saveData = (plan) =>{
    var data = {
      plan: plan,
    }

    sessionStorage.setItem('planData', JSON.stringify(data));
    // console.log(JSON.parse(sessionStorage.getItem('planData')));
  }

  return(
    <Fragment>
      <div className="overflow-x-hidden w-full h-full bg-page">
        <div className="flex justify-center">
          <div className="flex flex-col w-11/12 h-40 pt-[3.2%]">
            <div className="text-titulo text-center">
              ¿Qué libro quieres escribir?
              {console.log("Regalo?",props.location.state.regalo)}
            </div>
            <div className="text-center w-full font-cormorant text-letra1 text-[35px]">
            Todos nuestros libros tienen tapa dura, están cosidos en cuadernillos, <br /> 
            miden 21,5 cm de alto y 14,5 cm de ancho, y el papel interior es un bond de <br /> 90 gramos.
            Las tapas están impresas en color y el interior en blanco y negro.
            </div>

            <div className="grid grid-cols-3 gap-x-24 gap-y-8 mt-6 bg-page">
              <div className="col-span-1"></div>
              <div className="col-span-1 w-[55%] h-[65%] mx-auto">
                <img src={libro2} alt="Portada" />
                <Plan60 plan60={plan60} saveData={saveData} regalo={props.location.state.regalo}/>
              </div>
              <div className="col-span-1"></div>
            </div>
            {/* <div className="grid grid-cols-3 gap-x-24 gap-y-8 mt-6 bg-page">
              <div className="Primera columna Primera Fila w-[55%] h-[65%] mx-auto">
                <img src={libro1}/>
              </div>
              <div className="Segunda columna Primera Fila w-[55%] h-[65%] mx-auto">
                <img src={libro2} />
              </div>
              <div className="Tercera columna Primera fila w-[55%] h-[65%] mx-auto">
                <img src={libro3} />
              </div>
              <div className="Primera columna Segunda Fila w-[75%] h-[65%] mx-auto">
                <Plan1 plan1={plan1} saveData={saveData} regalo={props.location.state.regalo}/>
              </div>
              <div className="Segunda columna Segunda Fila w-[75%] h-[65%] mx-auto">
                <Plan2 plan2={plan2} soloLectura={soloLectura} saveData={saveData} regalo={props.location.state.regalo}/>
              </div>
              <div className="Tercera columna Segunda Fila w-[75%] h-[65%] mx-auto">
                <Plan3 plan3={plan3} soloLectura={soloLectura} saveData={saveData} regalo={props.location.state.regalo}/>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Planes;